import axios from 'axios'

const getDefaultState = () => ({
  wallpapers: [],
})

const state = getDefaultState()

const getters = {
  wallpapers: state => state.wallpapers,
  wallpaper: state => id => state.wallpapers.find(wp => wp.id == id),
}

const mutations = {
  SET_ALL: (state, wallpapers) => (state.wallpapers = wallpapers),

  SET_ONE: (state, wallpaper) => {
    const target = state.wallpapers.find(wp => wp.id == wallpaper.id)
    Object.assign(target, wallpaper)
  },

  ADD_ONE: (state, wallpaper) => state.wallpapers.push(wallpaper),
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/wallpapers'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  create: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/wallpapers'
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      const res = await axios.post(url, formData, config)
      commit('ADD_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/wallpapers/${id}?_method=PATCH`
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      const res = await axios.post(url, formData, config)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
