<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>visible</slot>
    </title>
    <path
      d="M32,26a6,6,0,1,0,3.11,11.13,6.1,6.1,0,0,0,2-2A6,6,0,0,0,32,26Zm0,0a6,6,0,1,0,3.11,11.13,6.1,6.1,0,0,0,2-2A6,6,0,0,0,32,26Zm19.08,4.76a0,0,0,0,1,0,0l-.51-.63c-.18-.22-.39-.46-.61-.71-.56-.63-1.22-1.34-2-2.07-.3-.29-.62-.59-.95-.88a28.38,28.38,0,0,0-6.11-4.24c-.48-.24-1-.46-1.46-.67A19.25,19.25,0,0,0,32,20a17.19,17.19,0,0,0-1.77.09l-.7.08-.33,0-.64.11c-.57.11-1.13.24-1.68.4-.32.09-.63.19-.95.3l-.92.34c-.31.12-.61.25-.9.38l0,0A23.75,23.75,0,0,0,21,23.41l-.72.47a33.78,33.78,0,0,0-7.36,6.88,2,2,0,0,0,0,2.48l.52.64c.18.22.39.46.61.71.55.63,1.21,1.34,2,2.07.3.29.62.59.95.88a28.38,28.38,0,0,0,6.11,4.24c.48.24,1,.46,1.46.67A19.25,19.25,0,0,0,32,44a17.19,17.19,0,0,0,1.77-.09l.7-.08.33,0,.64-.11c.57-.11,1.13-.24,1.68-.4.32-.09.63-.19,1-.3l.92-.34c.31-.12.59-.27.88-.4A23.75,23.75,0,0,0,43,40.59l.72-.47a33.69,33.69,0,0,0,7.35-6.87h0A2,2,0,0,0,51.08,30.76Zm-13.9,8.59a5.65,5.65,0,0,1-.51.34,8.5,8.5,0,0,1-2,.9c-.19.06-.39.12-.58.16s-.45.1-.68.14a6.31,6.31,0,0,1-.76.08q-.33,0-.66,0a9,9,0,0,1-9-9q0-.33,0-.66a6.53,6.53,0,0,1,.08-.76c0-.23.08-.46.14-.68s.1-.39.16-.58a8.93,8.93,0,0,1,.9-2,5.65,5.65,0,0,1,.34-.51,8.61,8.61,0,0,1,2.17-2.17,5.65,5.65,0,0,1,.51-.34,8.93,8.93,0,0,1,2-.9c.19-.06.39-.12.58-.16s.45-.1.68-.14a6.53,6.53,0,0,1,.76-.08q.33,0,.66,0a9,9,0,0,1,9,9q0,.33,0,.66a6.31,6.31,0,0,1-.08.76c0,.23-.08.46-.14.68s-.1.39-.16.58a8.5,8.5,0,0,1-.9,2,5.65,5.65,0,0,1-.34.51A8.61,8.61,0,0,1,37.18,39.35Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VisibleIcon',
}
</script>
