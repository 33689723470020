<template>
  <div>
    <GoBack :to="{ name: 'PreiseIndex' }">Preise</GoBack>

    <h1>Preis &rarr; anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="label">(internes) Label*</FormLabelInline>
          <FormInput
            v-model="formData.label"
            name="label"
            type="text"
            placeholder="Standard"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="preis">Preis*</FormLabelInline>
          <FormInput v-model="formData.preis" name="preis" type="number" step="0.01" :rqd="true" />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

export default {
  name: 'PreiseCreate',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
  },
  props: ['kat'],
  data() {
    return {
      formData: {
        label: '',
        preis: 2,
      },
      formErrors: null,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('preise/getAll')
    next()
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('preise/create', {
          label: this.formData.label,
          preis: this.formData.preis * 100,
        })
        this.$router.push({ name: 'PreiseIndex' })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
