<template>
  <div
    class="form-box-fields"
    :data-has-title="hasTitle"
    :data-title="title"
    :style="`max-width: ${maxWidth}`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormBoxFields',
  props: {
    title: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: String,
      default: '50rem',
    },
  },
  computed: {
    hasTitle() {
      return !!this.title
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';

.form-box-fields {
  display: block;
  position: relative;
  width: 100%;
  margin-top: 0.6875rem;
  padding: 3rem 1rem;
  border: 1px solid $border-col;
  border-radius: 0.5rem;

  &:not(:first-child) {
    margin-top: calc(0.6875rem + 4rem);
  }

  &[data-has-title='true']::after {
    content: attr(data-title);
    display: block;
    position: absolute;
    top: -0.6875rem;
    left: 1.5rem;
    padding: 0.125rem 1rem;
    font-size: 1.125rem;
    letter-spacing: $tracking-wide;
    color: $col-primary-800;
    background: $body-bg;
  }
}
</style>
