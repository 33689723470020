<template>
  <button
    v-if="type == 'button' || type == 'submit'"
    class="button"
    :type="type"
    :class="[mode, { deactivated }]"
    :style="`min-width: ${minWidth}`"
    @click="emitClick"
    @submit="emitSubmit"
  >
    <span>
      <slot></slot>
    </span>
  </button>

  <router-link v-else class="button" :to="to" :class="`${mode}`" :style="`min-width: ${minWidth}`">
    <span>
      <slot></slot>
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'ButtonButton',
  props: {
    type: {
      type: String,
      validator(value) {
        return ['button', 'submit', 'link'].includes(value)
      },
      default: 'button',
    },
    mode: {
      type: String,
      validator(value) {
        return ['std', 'faint', 'danger'].includes(value)
      },
      default: 'std',
    },
    minWidth: {
      type: String,
      default: '10rem',
    },
    to: {
      type: [String, Object],
      default: '/',
    },
    deactivated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick(event) {
      if (this.deactivated) return
      this.$emit('click', event)
    },
    emitSubmit(event) {
      if (this.deactivated) return
      this.$emit('submit', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.125rem;
  padding: 0 0.75rem;
  border-radius: $button-border-radius;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  letter-spacing: $button-tracking;
  transition: $button-transition;

  span {
    display: inline-block;
    transform: translateY(-0.0625em);
  }

  &:not(.deactivated) {
    cursor: pointer;

    &:active:not(.deactivated) {
      transform: scale(0.98);
    }

    &.std {
      color: $button-std-col;
      background: $button-std-bg;

      &:hover {
        background: $button-std-bg-hover;
      }
    }

    &.danger {
      color: $button-danger-col;
      background: $button-danger-bg;

      &:hover {
        background: $button-danger-bg-hover;
      }
    }

    &.faint {
      color: $button-faint-col;
      background: $button-faint-bg;

      &:hover {
        background: $button-faint-bg-hover;
      }
    }
  }

  &.deactivated {
    color: $button-faint-col;
    background: $button-faint-bg;
    cursor: default;
  }
}
</style>
