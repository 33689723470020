<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Media offset</slot>
    </title>
    <rect x="24" y="12" width="24" height="2" rx="1" />
    <rect x="24" y="17" width="24" height="2" rx="1" />
    <rect x="24" y="45" width="24" height="2" rx="1" />
    <rect x="24" y="50" width="24" height="2" rx="1" />
    <rect x="16" y="24" width="24" height="16" rx="1" />
  </svg>
</template>

<script>
export default {
  name: 'MediaOffsetIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
