<template>
  <div>
    <GoBack :to="{ name: 'ProdukteShow', params: { produktId: prod } }">
      Produkt
    </GoBack>

    <h1>Variante &rarr; anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="beschreibung">Beschreibung</FormLabelInline>
          <FormTextarea
            v-model="formData.beschreibung"
            name="beschreibung"
            width="32rem"
            :af="true"
          />
        </FormRowFields>

        <!-- Required Eigenschaften (Produktfilter) -->
        <FormRowFields v-for="{ id, titel } in requiredEigenschaftsarten" :key="id">
          <FormLabelInline :for="titel">{{ titel }}*</FormLabelInline>
          <select :name="titel" @change="selectRequiredEigenschaft">
            <option
              v-for="eigenschaft in eigenschaftenForArt(id)"
              :key="eigenschaft.id"
              :value="eigenschaft.id"
            >
              {{ eigenschaft.titel }}
            </option>
          </select>
        </FormRowFields>

        <!-- Optional Eigenschaften -->
        <FormRowFields>
          <FormLabelInline for="eigenschaften">
            <template v-if="requiredEigenschaftsarten.length">Weitere</template>
            Eigenschaften
          </FormLabelInline>
          <select
            class="select-eigenschaftsarten"
            name="eigenschaften"
            v-model="selectedEigenschaftsartId"
          >
            <option v-for="{ id, label } in remainingEigenschaftsarten" :key="id" :value="id">
              {{ label }}
            </option>
          </select>
          <select
            class="select-eigenschaften"
            v-if="selectedEigenschaftsartId"
            name="eigenschaften"
            @change="selectOptionalEigenschaft"
          >
            <option :value="null"></option>
            <option
              v-for="{ id, titel } in eigenschaftenForArt(selectedEigenschaftsartId)"
              :key="id"
              :value="id"
            >
              {{ titel }}
            </option>
          </select>
        </FormRowFields>

        <FormRowFields v-if="formData.eigenschaften.length > requiredEigenschaftsarten.length">
          <ul class="selected-list">
            <li v-for="id in selectedOptionalEigenschaften" :key="id">
              <span class="eigenschaftsart">
                {{
                  eigenschaftsarten.find(
                    art => art.id == eigenschaften.find(e => e.id == id).eigenschaftsartId
                  ).titel
                }}:
              </span>
              <span>{{ eigenschaften.find(e => e.id == id).titel }}</span>
              <span class="remove" @click="removeEigenschaft(id)">
                <XCircleIcon class="remove-icon" />
              </span>
            </li>
          </ul>
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="preisId">Preis (überschreibt Produktpreis)</FormLabelInline>
          <select name="preisId" v-model="formData.preisId">
            <option :value="null" selected="selected">&mdash;</option>
            <option v-for="{ id, label, cent } in preise" :key="id" :value="id">
              {{ label }} ({{ formatPrice(cent) }})
            </option>
          </select>
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="bestand">Bestand (tatsächlich)*</FormLabelInline>
          <FormInput
            v-model="formData.bestand"
            name="bestand"
            type="number"
            step="1"
            :rqd="true"
            width="3rem"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="bestandVersteckt">...davon versteckt</FormLabelInline>
          <FormInput
            v-model="formData.bestandVersteckt"
            name="bestandVersteckt"
            type="number"
            step="1"
            width="3rem"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="bestandSchwelle">Warnung für niedrigen Bestand</FormLabelInline>
          <FormInput
            v-model="formData.bestandSchwelle"
            name="bestandSchwelle"
            type="number"
            step="1"
            width="3rem"
          />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

import XCircleIcon from '@/components/cmsIcons/XCircleIcon'

export default {
  name: 'VariantenCreate',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
    XCircleIcon,
  },
  props: ['prod'],
  data() {
    return {
      formData: {
        produktId: this.prod,
        beschreibung: '',
        eigenschaften: [],
        preisId: null,
        bestand: null,
        bestandVersteckt: null,
        bestandSchwelle: 10,
      },
      formErrors: null,
      selectedEigenschaftsartId: null,
    }
  },
  computed: {
    produkt() {
      return store.getters['produkte/produkt'](this.prod)
    },
    produktgruppe() {
      return store.getters['produktgruppen/produktgruppe'](this.produkt.produktgruppeId)
    },
    eigenschaftsarten: () => store.getters['eigenschaftsarten/eigenschaftsarten'],
    eigenschaften: () => store.getters['eigenschaften/eigenschaften'],
    preise: () => store.getters['preise/preise'],
    requiredEigenschaftsarten() {
      return this.eigenschaftsarten.filter(art =>
        this.produkt.variantenfilter.map(f => f.id).includes(art.id)
      )
    },
    optionalEigenschaftsarten() {
      return this.eigenschaftsarten.filter(
        art =>
          !(
            this.produktgruppe.produktfilter.map(f => f.id).includes(art.id) ||
            this.produkt.eigenschaften.map(e => e.eigenschaftsartId).includes(art.id) ||
            this.produkt.variantenfilter.map(f => f.id).includes(art.id)
          )
      )
    },
    remainingEigenschaftsarten() {
      const selectedEigenschaftsartenIds = this.formData.eigenschaften.map(
        selId => this.eigenschaften.find(e => e.id == selId).eigenschaftsartId
      )
      return this.optionalEigenschaftsarten.filter(
        art => !selectedEigenschaftsartenIds.includes(art.id)
      )
    },
    selectedOptionalEigenschaften() {
      return this.formData.eigenschaften.filter(selId =>
        this.optionalEigenschaftsarten
          .map(art => art.id)
          .includes(this.eigenschaften.find(e => e.id == selId).eigenschaftsartId)
      )
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('produkte/getAll'),
      store.dispatch('eigenschaftsarten/getAll'),
      store.dispatch('eigenschaften/getAll'),
      store.dispatch('preise/getAll'),
    ])
    next()
  },
  created() {
    this.produkt.variantenfilter.forEach(art =>
      this.formData.eigenschaften.push(this.eigenschaftenForArt(art.id)[0].id)
    )
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    eigenschaftenForArt(artId) {
      return store.getters['eigenschaften/eigenschaftenForEigenschaftsart'](artId)
    },
    selectRequiredEigenschaft(event) {
      const id = event.target.value
      const artId = this.eigenschaften.find(e => e.id == id).eigenschaftsartId
      const index = this.formData.eigenschaften.findIndex(
        selected => this.eigenschaften.find(e => e.id == selected).eigenschaftsartId == artId
      )
      this.formData.eigenschaften[index] = id
    },
    selectOptionalEigenschaft(event) {
      this.formData.eigenschaften.push(event.target.value)
      this.selectedEigenschaftsartId = null
      event.target.value = null
    },
    removeEigenschaft(id) {
      this.formData.eigenschaften.splice(
        this.formData.eigenschaften.findIndex(selId => selId == id),
        1
      )
    },
    async submit() {
      try {
        await this.$store.dispatch('varianten/create', {
          produktId: this.prod,
          ...(this.formData.beschreibung && { beschreibung: this.formData.beschreibung }),
          eigenschaften: this.formData.eigenschaften,
          preisId: this.formData.preisID,
          bestand: this.formData.bestand,
          ...(this.formData.bestandVersteckt && {
            bestandVersteckt: this.formData.bestandVersteckt,
          }),
          ...(this.formData.bestandSchwelle && {
            bestandSchwelle: this.formData.bestandSchwelle,
          }),
        })
        this.$router.push({
          name: 'ProdukteShow',
          params: { produktId: this.prod },
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.select-eigenschaftsarten,
.select-eigenschaften {
  width: 15rem;
}

.select-eigenschaften {
  margin-left: 2rem;
}

.selected-list {
  margin-left: 16rem;

  li:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.eigenschaftsart {
  margin-right: 0.25rem;
  font-weight: $weight-semibold;
}

.remove {
  display: inline-block;
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}

.remove-icon {
  display: inline-block;
  height: 1.25rem;
  transform: translateY(0.25rem);
  fill: $col-red-500;
}
</style>
