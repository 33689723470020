<template>
  <div>
    <GoBack :to="{ name: 'BestellungenIndex' }">Bestellungen</GoBack>

    <GoBack :to="{ name: 'BestellungenShow', params: { bestellnummer } }">
      Bestellung: {{ bestellnummer }}
    </GoBack>

    <h1>Bestellung: {{ bestellnummer }} &rarr; bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="teaser">Teaser</FormLabelInline>
          <FormTextarea v-model="formData.teaser" name="teaser" width="32rem" :rows="2" />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="beschreibung">Beschreibung</FormLabelInline>
          <FormTextarea v-model="formData.beschreibung" name="beschreibung" width="32rem" />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="hinweis">Hinweis</FormLabelInline>
          <FormTextarea v-model="formData.hinweis" name="hinweis" width="32rem" />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

export default {
  name: 'BestellungenEdit',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
  },
  props: ['bestellnummer'],
  data() {
    return {
      formData: null,
      formErrors: null,
    }
  },
  computed: {
    bestellung() {
      return store.getters['bestellungen/bestellung'](this.bestellnummer)
    },
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('bestellungen/getAll')
    next()
  },
  created() {
    this.formData = {
      // teaser: this.produkt.teaser,
      // beschreibung: this.produkt.beschreibung,
      // hinweis: this.produkt.hinweis,
      // eigenschaften: this.produkt.eigenschaften.map(e => e.id),
      // preisId: this.produkt.preis.id,
      // quickbuy: this.produkt.quickbuy,
      // neuFuer: this.produkt.neuFuer,
      // variantenfilter: this.produkt.variantenfilter.map(p => p.id),
    }
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    async submit() {
      try {
        await this.$store.dispatch('bestellungen/update', {
          bestellnummer: this.bestellnummer,
          formData: this.formData,
        })
        this.$router.push({
          name: 'BestellungShow',
          params: { bestellnummer: this.bestellnummer },
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
