<template>
  <div>
    <GoBack :to="{ name: 'BestellungenIndex' }">Bestellungen</GoBack>

    <h1>Bestellung: {{ bestellnummer }}</h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Bestellnummer</th>
          <td>{{ bestellnummer }}</td>
        </tr>

        <tr>
          <th>Name</th>
          <td>{{ kunde.vorname }} {{ kunde.nachname }}</td>
        </tr>

        <tr>
          <th>Email</th>
          <td>{{ kunde.email }}</td>
        </tr>

        <tr>
          <th>Rechungsadresse</th>
          <td>
            <p>{{ rechnungsadresse.vorname }} {{ rechnungsadresse.nachname }}</p>
            <p v-if="rechnungsadresse.firma">{{ rechnungsadresse.firma }}</p>
            <p>{{ rechnungsadresse.strasse }}</p>
            <p>{{ rechnungsadresse.plz }} {{ rechnungsadresse.ort }}</p>
            <p>{{ rechnungsadresse.land }}</p>
          </td>
        </tr>

        <tr>
          <th>Lieferadresse</th>
          <td>
            <p>{{ lieferadresse.vorname }} {{ lieferadresse.nachname }}</p>
            <p v-if="lieferadresse.firma">{{ lieferadresse.firma }}</p>
            <p>{{ lieferadresse.strasse }}</p>
            <p>{{ lieferadresse.plz }} {{ lieferadresse.ort }}</p>
            <p>{{ lieferadresse.land }}</p>
          </td>
        </tr>

        <tr>
          <th>Gesamtsumme</th>
          <td>{{ formatPrice(bestellung.gesamtsumme) }}</td>
        </tr>

        <tr>
          <th>Bestellung</th>
          <td v-if="bestellung.bestellung">
            {{ bestellung.bestellung.datum }} {{ bestellung.bestellung.uhrzeit }}
          </td>
          <td v-else></td>
        </tr>

        <tr>
          <th>Bezahlung</th>
          <td v-if="bestellung.bezahlung">
            {{ bestellung.bezahlung.datum }} {{ bestellung.bezahlung.uhrzeit }}
          </td>
          <td v-else></td>
        </tr>

        <tr>
          <th>Versand</th>
          <td v-if="bestellung.versand">
            {{ bestellung.versand.datum }} {{ bestellung.versand.uhrzeit }}
          </td>
          <td v-else></td>
        </tr>

        <tr>
          <th>Abbruch</th>
          <td v-if="bestellung.abbruch">
            {{ bestellung.abbruch.datum }} {{ bestellung.abbruch.uhrzeit }}
          </td>
          <td v-else></td>
        </tr>

        <tr>
          <th>Problem</th>
          <td v-if="bestellung.problem">
            {{ bestellung.problem.datum }} {{ bestellung.problem.uhrzeit }}
          </td>
          <td v-else></td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'BestellungenEdit', params: { bestellnummer } }">
      Bearbeiten
    </VButton>

    <VSpacer />

    <h2>Posten</h2>

    <VSpacer mode="small" />

    <ListTable v-if="posten">
      <thead>
        <tr>
          <th>Stk.</th>
          <th>Bezeichung</th>
          <th>Wert</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="{ id, variante, anzahl, warenwert } in posten" :key="id">
          <td>{{ anzahl }}</td>

          <td>[Bezeichnung]</td>

          <td>{{ formatPrice(warenwert) }}</td>
        </tr>
      </tbody>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'BestellungenShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
  },
  props: ['bestellnummer'],
  data() {
    return {
      formData: null,
      formErrors: null,
      formDataAltered: false,
    }
  },
  computed: {
    bestellung() {
      return store.getters['bestellungen/bestellung'](this.bestellnummer)
    },
    kunde: vm => vm.bestellung.kunde,
    rechnungsadresse: vm => vm.kunde.rechnungsadresse,
    lieferadresse: vm => vm.bestellung.lieferadresse,
    posten: vm => vm.bestellung.posten,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('bestellungen/getAll')
    next()
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
  },
}
</script>

<style lang="scss" scoped>
.inline-list:not(:last-of-type)::after {
  display: inline-block;
  content: '|';
}

.table-icon {
  height: 2rem;
  fill: $col-white;
}
</style>
