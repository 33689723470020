<template>
  <div>
    <GoBack :to="{ name: 'EigenschaftsartenShow', params: { eigenschaftsartId: eigArt } }">
      Eigenschaftsart
    </GoBack>

    <h1>Eigenschaft &rarr; anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="titel">Titel (für Auswahl)*</FormLabelInline>
          <FormInput
            v-model="formData.titel"
            name="titel"
            type="text"
            placeholder="100% Baumwolle"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="beschreibung">Beschreibung (für Infotext)</FormLabelInline>
          <FormTextarea v-model="formData.beschreibung" name="beschreibung" width="32rem" />
        </FormRowFields>
      </FormBoxFields>
      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

export default {
  name: 'EigenschaftenCreate',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
  },
  props: ['eigArt'],
  data() {
    return {
      formData: {
        titel: '',
        beschreibung: '',
        eigenschaftsartId: this.eigArt,
      },
      formErrors: null,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('eigenschaften/getAll')
    next()
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('eigenschaften/create', this.formData)
        this.$router.push({
          name: 'EigenschaftsartenShow',
          params: { eigenschaftsartId: this.eigArt },
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
