<template>
  <label class="switch">
    <!-- 
      // NOTE:
      HTML/CSS does not get updated in Firefox when checked-value changes
      This is the reason for the v-if/v-else-solution above
     -->
    <input
      v-if="value"
      :name="name"
      class="checkbox checkbox--checked"
      type="checkbox"
      @click.prevent="handleClick"
      checked
    />
    <input v-else :name="name" class="checkbox" type="checkbox" @click.prevent="handleClick" />
    <span class="toggle"></span>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick() {
      // emit intended value
      this.$emit('click', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  width: max-content;
  align-items: center;
  cursor: pointer;
  font-family: $font;
  font-weight: $weight-semibold;
  letter-spacing: $tracking-wide;
}

.toggle {
  display: inline-block;
  position: relative;
  width: 52px;
  height: 24px;
  margin-right: 1rem;
  background: $col-gray-300;
  border-radius: 14px;
  transition: background-color 250ms ease;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 28px;
    height: 20px;
    top: 50%;
    left: 50%;
    border: 1px solid $border-col;
    border-radius: 1rem;
    background: $col-white;
    transform: translate(calc(-50% - ((52px - 28px) / 2 - 2px)), -50%);
    transition: transform 250ms ease;
  }
}

.checkbox--checked + .toggle {
  background: $col-primary-500;

  &::after {
    transform: translate(calc(-50% + ((52px - 28px) / 2 - 2px)), -50%);
  }
}
</style>
