var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Bestellungen")]),(_vm.bestellungen)?_c('ListTable',[_c('thead',[_c('tr',[_c('th',[_vm._v("Best.Nr.")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Summe")]),_c('th',[_vm._v("Z.-Art")]),_c('th',[_vm._v("Bestellt")]),_c('th',[_vm._v("Bezahlt")]),_c('th',[_vm._v("Versandt")]),_c('th',[_vm._v("Abbruch")]),_c('th',[_vm._v("Problem")])])]),_c('tbody',_vm._l((_vm.bestellungen),function(ref){
        var bestellnummer = ref.bestellnummer;
        var zahlungsart = ref.zahlungsart;
        var gesamtsumme = ref.gesamtsumme;
        var bestellung = ref.bestellung;
        var bezahlung = ref.bezahlung;
        var versand = ref.versand;
        var abbruch = ref.abbruch;
        var problem = ref.problem;
        var kunde = ref.kunde;
return _c('tr',{key:bestellnummer},[_c('TdLink',{attrs:{"to":{ name: 'BestellungenShow', params: { bestellnummer: bestellnummer } }}},[_vm._v(" "+_vm._s(bestellnummer)+" ")]),_c('td',[_vm._v(_vm._s(kunde.vorname)+" "+_vm._s(kunde.nachname))]),_c('td',[_vm._v(_vm._s(_vm.formatPrice(gesamtsumme)))]),_c('td',[_vm._v(_vm._s(zahlungsart))]),_c('td',[_vm._v(_vm._s(bestellung ? bestellung.datum : ''))]),_c('td',[(bezahlung)?[_vm._v(_vm._s(bezahlung.datum))]:_c('TableButton',{staticClass:"button",on:{"click":function($event){return _vm.setBezahlt(bestellnummer, $event)}}},[_vm._v(" BEZAHLT ")])],2),_c('td',[(versand)?[_vm._v(_vm._s(versand.datum))]:_c('TableButton',{staticClass:"button",on:{"click":function($event){return _vm.setVersandt(bestellnummer, $event)}}},[_vm._v(" VERSANDT ")])],2),_c('td',[(abbruch)?[_vm._v(_vm._s(abbruch.datum))]:_c('TableButton',{staticClass:"button",on:{"click":function($event){return _vm.setAbgebrochen(bestellnummer, $event)}}},[_vm._v(" ABBRUCH ")])],2),_c('td',[(problem)?[_vm._v(_vm._s(problem.datum))]:_c('TableButton',{staticClass:"button",on:{"click":function($event){return _vm.setProblem(bestellnummer, $event)}}},[_vm._v(" PROBLEM ")])],2)],1)}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }