<template>
  <div class="order">
    <input :value="order" class="input" type="number" @keyup="handleKeyup($event)" />
    <div class="handle">
      <drag-handle size="1rem"></drag-handle>
    </div>
  </div>
</template>

<script>
import DragHandle from '@/components/cmsIcons/DragHandle'

export default {
  name: 'TableOrder',
  components: {
    DragHandle,
  },
  props: {
    order: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newOrder: null,
    }
  },
  methods: {
    handleKeyup(e) {
      if (e.key === 'Enter') {
        this.emitSubmit(e)
        e.target.blur()
      } else {
        this.newOrder = e.target.value
      }
    },
    emitSubmit() {
      this.$emit('submit', this.newOrder)
    },
  },
}
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  align-items: center;
}

.handle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  cursor: move;
}

.input {
  position: relative;
  width: 2rem;
  height: 1.5rem;
  padding: 0 0.125rem;
  font-family: monospace;
  font-size: 0.9325rem;
  text-align: right;
  vertical-align: middle;
  border-radius: 4px;
  background: $col-gray-50;

  &:focus,
  &:active {
    background: $col-gray-100;
  }
}
</style>
