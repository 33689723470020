<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Underline</slot>
    </title>
    <path
      d="M49.21,16.37v1.35c-3,.19-4.13,1-4.22,3.17-.05,1.06-.14,3-.15,7.11v1.61c0,4.89-.57,8.64-3.36,11.57-2.25,2.35-5.52,3.07-8.83,3.07A13,13,0,0,1,24.78,42C21.9,39.83,20.5,36.52,20.5,30.9V22.66c0-4.32-.38-4.61-4.22-4.94V16.37H28.91v1.35c-3.85.24-4.23.62-4.23,4.94v8c0,7,3.31,10.85,9.07,10.85,6.82,0,9-4.22,9-12.19V28c0-4.11-.14-5.86-.24-7.11-.14-2.16-1.25-3-4.8-3.17V16.37ZM47.5,48h-31a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h31a.5.5,0,0,0,.5-.5v-2A.5.5,0,0,0,47.5,48Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'UnderlineIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
