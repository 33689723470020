export default {
  namespaced: true,

  state: {
    active: false,
    mode: 'std',
    message: '',
  },

  getters: {
    active: state => state.active,
    message: state => state.message,
    mode: state => state.mode,
  },

  mutations: {
    SHOW(state, { message, mode }) {
      state.active = true
      state.message = message
      state.mode = mode ?? 'std'
    },
    HIDE(state) {
      state.active = false
    },
  },

  actions: {
    flashMessage({ commit }, { message, mode, duration = 4000 }) {
      commit('SHOW', { message, mode })
      setTimeout(() => {
        commit('HIDE')
      }, duration)
    },
  },
}
