import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Auth from '../layouts/Auth.vue'
import Guest from '../layouts/Guest.vue'

import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'

import BestellungenIndex from '../views/Bestellungen/Index.vue'
import BestellungenShow from '../views/Bestellungen/Show.vue'
import BestellungenEdit from '../views/Bestellungen/Edit.vue'

import KategorienIndex from '../views/Kategorien/Index.vue'
import KategorienCreate from '../views/Kategorien/Create.vue'
import KategorienShow from '../views/Kategorien/Show.vue'

import PreiseIndex from '../views/Preise/Index.vue'
import PreiseCreate from '../views/Preise/Create.vue'
import PreiseShow from '../views/Preise/Show.vue'

import EigenschaftsartenIndex from '../views/Eigenschaftsarten/Index.vue'
import EigenschaftsartenCreate from '../views/Eigenschaftsarten/Create.vue'
import EigenschaftsartenShow from '../views/Eigenschaftsarten/Show.vue'
import EigenschaftsartenEdit from '../views/Eigenschaftsarten/Edit.vue'

import EigenschaftenCreate from '../views/Eigenschaften/Create.vue'
import EigenschaftenShow from '../views/Eigenschaften/Show.vue'

import ProduktbilderIndex from '../views/Produktbilder/Index.vue'
import ProduktbilderCreate from '../views/Produktbilder/Create.vue'

import WarenbewegungenIndex from '../views/Warenbewegungen/Index.vue'
import WarenbewegungenCreate from '../views/Warenbewegungen/Create.vue'

import ProduktgruppenCreate from '../views/Produktgruppen/Create.vue'
import ProduktgruppenShow from '../views/Produktgruppen/Show.vue'
import ProduktgruppenEdit from '../views/Produktgruppen/Edit.vue'

import ProdukteIndex from '../views/Produkte/Index.vue'
import ProdukteCreate from '../views/Produkte/Create.vue'
import ProdukteShow from '../views/Produkte/Show.vue'
import ProdukteEdit from '../views/Produkte/Edit.vue'

import VariantenCreate from '../views/Varianten/Create.vue'
import VariantenShow from '../views/Varianten/Show.vue'
import VariantenEdit from '../views/Varianten/Edit.vue'

import WallpapersIndex from '../views/Wallpapers/Index.vue'
import WallpapersEdit from '../views/Wallpapers/Edit.vue'

import AushaengeIndex from '../views/Aushaenge/Index.vue'
import AushaengeCreate from '../views/Aushaenge/Create.vue'
import AushaengeEdit from '../views/Aushaenge/Edit.vue'

import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Auth,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/bestellungen',
        name: 'BestellungenIndex',
        component: BestellungenIndex,
      },
      {
        path: '/bestellungen/:bestellnummer',
        name: 'BestellungenShow',
        component: BestellungenShow,
        props: true,
      },
      {
        path: '/bestellungen/:bestellnummer/bearbeiten',
        name: 'BestellungenEdit',
        component: BestellungenEdit,
        props: true,
      },
      {
        path: '/kategorien',
        name: 'KategorienIndex',
        component: KategorienIndex,
      },
      {
        path: '/kategorien/neu',
        name: 'KategorienCreate',
        component: KategorienCreate,
      },
      {
        path: '/kategorien/:kategorieId',
        name: 'KategorienShow',
        component: KategorienShow,
        props: true,
      },
      {
        path: '/preise',
        name: 'PreiseIndex',
        component: PreiseIndex,
      },
      {
        path: '/preise/neu',
        name: 'PreiseCreate',
        component: PreiseCreate,
      },
      {
        path: '/preise/:preisId',
        name: 'PreiseShow',
        component: PreiseShow,
        props: true,
      },
      {
        path: '/eigenschaftsarten',
        name: 'EigenschaftsartenIndex',
        component: EigenschaftsartenIndex,
      },
      {
        path: '/eigenschaftsarten/neu',
        name: 'EigenschaftsartenCreate',
        component: EigenschaftsartenCreate,
      },
      {
        path: '/eigenschaftsarten/:eigenschaftsartId',
        name: 'EigenschaftsartenShow',
        component: EigenschaftsartenShow,
        props: true,
      },
      {
        path: '/eigenschaftsarten/:eigenschaftsartId/bearbeiten',
        name: 'EigenschaftsartenEdit',
        component: EigenschaftsartenEdit,
        props: true,
      },
      {
        path: '/eigenschaften/neu',
        name: 'EigenschaftenCreate',
        component: EigenschaftenCreate,
        props: route => ({ eigArt: route.query.eigArt }),
      },
      {
        path: '/eigenschaften/:eigenschaftId',
        name: 'EigenschaftenShow',
        component: EigenschaftenShow,
        props: true,
      },
      {
        path: '/warenbewegungen',
        name: 'WarenbewegungenIndex',
        component: WarenbewegungenIndex,
      },
      {
        path: '/warenbewegungen/neu',
        name: 'WarenbewegungenCreate',
        component: WarenbewegungenCreate,
        props: route => ({ var: route.query.var }),
      },
      {
        path: '/produktbilder',
        name: 'ProduktbilderIndex',
        component: ProduktbilderIndex,
        props: route => ({ prod: route.query.prod }),
      },
      {
        path: '/produktbilder/neu',
        name: 'ProduktbilderCreate',
        component: ProduktbilderCreate,
        props: route => ({ prod: route.query.prod }),
      },
      {
        path: '/produktgruppen/neu',
        name: 'ProduktgruppenCreate',
        component: ProduktgruppenCreate,
        props: route => ({ kat: route.query.kat }),
      },
      {
        path: '/produktgruppen/:produktgruppeId',
        name: 'ProduktgruppenShow',
        component: ProduktgruppenShow,
        props: true,
      },
      {
        path: '/produktgruppen/:produktgruppeId/bearbeiten',
        name: 'ProduktgruppenEdit',
        component: ProduktgruppenEdit,
        props: true,
      },
      {
        path: '/produkte',
        name: 'ProdukteIndex',
        component: ProdukteIndex,
        props: route => ({ kat: route.query.kat }),
      },
      {
        path: '/produkte/neu',
        name: 'ProdukteCreate',
        component: ProdukteCreate,
        props: route => ({ gru: route.query.gru }),
      },
      {
        path: '/produkte/:produktId',
        name: 'ProdukteShow',
        component: ProdukteShow,
        props: true,
      },
      {
        path: '/produkte/:produktId/bearbeiten',
        name: 'ProdukteEdit',
        component: ProdukteEdit,
        props: true,
      },
      {
        path: '/varianten/neu',
        name: 'VariantenCreate',
        component: VariantenCreate,
        props: route => ({ prod: route.query.prod }),
      },
      {
        path: '/varianten/:varianteId',
        name: 'VariantenShow',
        component: VariantenShow,
        props: true,
      },
      {
        path: '/varianten/:varianteId/bearbeiten',
        name: 'VariantenEdit',
        component: VariantenEdit,
        props: true,
      },
      {
        path: '/wallpapers',
        name: 'WallpapersIndex',
        component: WallpapersIndex,
      },
      {
        path: '/wallpapers/:wallpaperId',
        name: 'WallpapersEdit',
        component: WallpapersEdit,
        props: true,
      },
      {
        path: '/aushaenge',
        name: 'AushaengeIndex',
        component: AushaengeIndex,
      },
      {
        path: '/aushaenge',
        name: 'AushaengeCreate',
        component: AushaengeCreate,
      },
      {
        path: '/aushaenge/:aushangId',
        name: 'AushaengeEdit',
        component: AushaengeEdit,
        props: true,
      },
    ],
  },
  {
    path: '/',
    component: Guest,
    meta: { requiresGuest: true },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
    ],
  },
  {
    path: '*',
    component: Auth,
    children: [
      {
        path: '',
        name: '404',
        component: PageNotFound,
        beforeEnter: (to, from, next) => {
          if (store.getters['auth/isAuth']) next()
          else next({ name: 'Login' })
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/getUser')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuth']) next({ name: 'Login' })
    else next()
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.getters['auth/isAuth']) next({ name: 'Home' })
    else next()
  } else {
    next()
  }
})

export default router
