<template>
  <div>
    <GoBack :to="{ name: 'ProdukteIndex', query: { kat: kategorie.id } }">
      Produkte: {{ kategorie.titel.plural }}
    </GoBack>

    <GoBack :to="{ name: 'ProduktgruppenShow', params: { produktgruppeId } }">
      Produktgruppe: {{ produktgruppe.titel }}
    </GoBack>

    <h1>Produktgruppe: &bdquo;{{ produktgruppe.titel }}&ldquo; &rarr; bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="titel">Titel*</FormLabelInline>
          <FormInput
            v-model="formData.titel"
            name="titel"
            type="text"
            placeholder="Pezimist"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="beschreibung">Beschreibung</FormLabelInline>
          <FormTextarea v-model="formData.beschreibung" name="beschreibung" width="32rem" />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="produktfilter">
            Produkt-Unterscheidungsmerkmale
          </FormLabelInline>
          <select
            class="select-eigenschaftsarten"
            name="produktfilter"
            @change="selectProduktfilter"
          >
            <option :value="null"></option>
            <option v-for="{ id, label } in remainingEigenschaftsarten" :key="id" :value="id">
              {{ label }}
            </option>
          </select>
        </FormRowFields>

        <FormRowFields v-if="formData.produktfilter.length">
          <ul class="selected-list">
            <li v-for="id in formData.produktfilter" :key="id">
              <span class="eigenschaftsart">
                {{ eigenschaftsarten.find(art => art.id == id).titel }}
              </span>
              <span class="remove" @click="removeProduktfilter(id)">
                <XCircleIcon class="remove-icon" />
              </span>
            </li>
          </ul>
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

import XCircleIcon from '@/components/cmsIcons/XCircleIcon'

export default {
  name: 'ProduktgruppenEdit',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
    XCircleIcon,
  },
  props: ['produktgruppeId'],
  data() {
    return {
      formData: null,
      formErrors: null,
    }
  },
  computed: {
    produktgruppe() {
      return store.getters['produktgruppen/produktgruppe'](this.produktgruppeId)
    },
    kategorie() {
      return store.getters['kategorien/kategorie'](this.produktgruppe.kategorieId)
    },
    eigenschaftsarten: () => store.getters['eigenschaftsarten/eigenschaftsarten'],
    remainingEigenschaftsarten() {
      return this.eigenschaftsarten.filter(art => !this.formData.produktfilter.includes(art.id))
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('kategorien/getAll'),
      store.dispatch('eigenschaftsarten/getAll'),
    ])
    next()
  },
  created() {
    this.formData = {
      titel: this.produktgruppe.titel,
      beschreibung: this.produktgruppe.beschreibung,
      produktfilter: this.produktgruppe.produktfilter.map(p => p.id),
    }
  },
  methods: {
    selectProduktfilter(event) {
      // need parseInt, because event.target.value returns a string
      this.formData.produktfilter.push(parseInt(event.target.value))
    },
    removeProduktfilter(id) {
      this.formData.produktfilter.splice(
        this.formData.produktfilter.findIndex(filter => filter == id),
        1
      )
    },
    async submit() {
      try {
        await this.$store.dispatch('produktgruppen/update', {
          id: this.produktgruppeId,
          formData: this.formData,
        })
        this.$router.push({
          name: 'ProduktgruppenShow',
          params: { produktgruppeId: this.produktgruppeId },
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.select-eigenschaftsarten {
  width: 15rem;
}

.selected-list {
  margin-left: 16rem;

  li:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.eigenschaftsart {
  margin-right: 0.25rem;
  font-weight: $weight-semibold;
}

.remove {
  display: inline-block;
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}

.remove-icon {
  display: inline-block;
  height: 1.25rem;
  transform: translateY(0.25rem);
  fill: $col-red-500;
}
</style>
