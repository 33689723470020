import axios from 'axios'
import Vue from 'vue'

const getDefaultState = () => ({
  produkte: [],
  produkt: {},
})

const state = getDefaultState()

const getters = {
  produkte: state => state.produkte,
  produkteForProduktgruppe: state => id =>
    state.produkte
      .filter(p => p.produktgruppeId == id)
      .sort((a, b) => a.orderWithinProduktgruppe - b.orderWithinProduktgruppe),
  produkt: state => id => state.produkte.find(prod => prod.id == id),
  onlyProdukt: state => state.produkt,
}

const mutations = {
  SET_ALL: (state, produkte) => (state.produkte = produkte),

  SET_ONE: (state, produkt) => {
    const target = state.produkte.find(prod => prod.id == produkt.id)
    Object.assign(target, produkt)
  },

  SET_ONLY: (state, produkt) => {
    state.produkt = produkt
  },

  ADD_ONE: (state, produkt) => state.produkte.push(produkt),
  // todo This is not exactly the right place
  // should be added as last entry of produkte with same kategorie

  SET_POSITION_WITHIN_KATEGORIE: (state, { id, position }) => {
    // sorts and sets order-value
    const index = state.produkte.findIndex(prod => prod.id == id)
    const katId = state.produkte[index].kategorieId
    const removed = state.produkte.splice(index, 1)[0]

    let pos = 0
    const targetIndex = state.produkte.findIndex(prod => {
      return prod.kategorieId == katId && position - 1 == pos++
    })
    state.produkte.splice(targetIndex, 0, removed)

    let order = 1
    state.produkte.filter(prod => prod.kategorieId == katId).forEach(prod => (prod.order = order++))
  },

  SET_DEFAULT_VARIANTE_ID: (state, { varianteId, produktId }) => {
    const target = state.produkte.find(prod => prod.id == produktId)
    Vue.set(target, 'defaultVarianteId', varianteId)
  },

  SET_PUBLISHED: (state, id) => {
    const target = state.produkte.find(prod => prod.id == id)
    Vue.set(target, 'publiziert', true)
  },

  SET_PRIVATE: (state, id) => {
    const target = state.produkte.find(prod => prod.id == id)
    Vue.set(target, 'publiziert', false)
  },
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/produkte'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  getOne: async ({ commit }, id) => {
    const url = `/produkte/${id}`
    const res = await axios.get(url)
    commit('SET_ONLY', res.data.data)
  },

  create: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/produkte'
      const res = await axios.post(url, formData)
      commit('ADD_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/produkte/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPositionWithinKategorie: ({ commit, dispatch }, payload) => {
    commit('SET_POSITION_WITHIN_KATEGORIE', payload)
    try {
      const url = `/produkte/${payload.id}/set-position/kategorie`
      const data = { position: payload.position }
      axios.post(url, data)
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite refreshen',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setDefaultVarianteId: ({ commit }, payload) => {
    commit('SET_DEFAULT_VARIANTE_ID', payload)
  },

  publish: async ({ commit, dispatch }, id) => {
    commit('SET_PUBLISHED', id)
    try {
      const url = `/produkte/${id}/publish`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'publiziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'publizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  unpublish: async ({ commit, dispatch }, id) => {
    commit('SET_PRIVATE', id)
    try {
      const url = `/produkte/${id}/unpublish`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'depubliziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'depublizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
