<template>
  <div>
    <GoBack :to="{ name: 'ProdukteIndex', query: { kat: kategorie.id } }">
      Produkte: {{ kategorie.titel.plural }}
    </GoBack>

    <GoBack :to="{ name: 'ProduktgruppenShow', params: { produktgruppeId: produktgruppe.id } }">
      Produktgruppe: {{ produktgruppe.titel }}
    </GoBack>

    <GoBack :to="{ name: 'ProdukteShow', params: { produktId: produkt.id } }">
      Produkt:
      <template v-if="produktgruppe.produktfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in produkt.eigenschaften.filter(e =>
            produktgruppe.produktfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
    </GoBack>

    <h1>
      Variante:
      <template v-if="produkt.variantenfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in variante.eigenschaften.filter(e =>
            produkt.variantenfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
    </h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Beschreibung</th>
          <td>
            <textarea
              type="text"
              v-model="formData.beschreibung"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>

        <tr>
          <th>Bestand</th>
          <td>
            <input type="number" v-model="formData.bestand" @keyup="formDataChanged($event)" />
          </td>
        </tr>

        <tr>
          <th>versteckter Bestand</th>
          <td>
            <input
              type="number"
              v-model="formData.bestandVersteckt"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>

        <tr>
          <th>Best.-Warnschwelle</th>
          <td>
            <input
              type="number"
              v-model="formData.bestandSchwelle"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>

        <tr>
          <th>Preis</th>
          <td>
            <template v-if="variante.preis">{{ formatPrice(variante.preis.cent) }}</template>
            <template v-else>&mdash;</template>
          </td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton :deactivated="!formDataAltered" @click="submit">Speichern</VButton>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'VariantenEdit', params: { varianteId } }">
      Bearbeiten
    </VButton>

    <VSpacer />

    <VToggle name="publizieren" :value="variante.publiziert" @click="togglePubliziert">
      publizieren
    </VToggle>

    <VSpacer />

    <template v-if="!variante.publiziert">
      <VButton
        v-if="!archiveActivated"
        type="button"
        mode="danger"
        @click="archiveActivated = true"
      >
        Archivieren
      </VButton>

      <template v-if="archiveActivated">
        <p>Bist du dir sicher?</p>

        <VSpacer mode="small" />

        <VButton type="button" @click="archiveActivated = false">
          Nein, abbrechen
        </VButton>

        <VButton type="button" mode="danger" @click="archive">
          JA
        </VButton>
      </template>
    </template>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'
import VToggle from '@/components/global/Toggle'

export default {
  name: 'VariantenShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
    VToggle,
  },
  props: ['varianteId'],
  data() {
    return {
      formData: null,
      formErrors: null,
      formDataAltered: false,
      archiveActivated: false,
    }
  },
  computed: {
    variante() {
      return store.getters['varianten/variante'](this.varianteId)
    },
    produkt() {
      return store.getters['produkte/produkt'](this.variante.produktId)
    },
    produktgruppe() {
      return store.getters['produktgruppen/produktgruppe'](this.produkt.produktgruppeId)
    },
    kategorie() {
      return store.getters['kategorien/kategorie'](this.produktgruppe.kategorieId)
    },
    eigenschaftsarten: () => store.getters['eigenschaftsarten/eigenschaftsarten'],
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('varianten/getAll'),
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('produkte/getAll'),
      store.dispatch('kategorien/getAll'),
      store.dispatch('eigenschaftsarten/getAll'),
    ])
    next()
  },
  created() {
    this.formData = {
      beschreibung: this.variante.beschreibung,
      bestand: this.variante.bestand.real,
      bestandVersteckt: this.variante.bestand.versteckt,
      bestandSchwelle: this.variante.bestand.schwelle,
    }
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    formDataChanged(/* event */) {
      /** // todo
       * validate event.target.value
       * if no errors, show saveChangesButton
       */
      this.formDataAltered = true
    },
    async submit() {
      try {
        await this.$store.dispatch('varianten/update', {
          id: this.varianteId,
          formData: this.formData,
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
    togglePubliziert(newValue) {
      if (newValue) {
        this.$store.dispatch('varianten/publish', this.varianteId)
      } else {
        this.$store.dispatch('varianten/unpublish', this.varianteId)
      }
    },
    archive() {
      const produktId = this.produkt.id
      this.$store.dispatch('varianten/archive', this.varianteId)
      this.$router.push({ name: 'ProdukteShow', params: { produktId } })
    },
  },
}
</script>

<style lang="scss" scoped>
.inline-list:not(:last-of-type)::after {
  display: inline-block;
  content: '|';
}
</style>
