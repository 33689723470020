<template>
  <div class="layout--guest">
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Guest',
}
</script>

<style lang="scss">
.layout--guest {
  height: 100%;
  background: $body-bg;
}
</style>
