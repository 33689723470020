<template>
  <div>
    <GoBack :to="{ name: 'PreiseIndex' }">Preise</GoBack>

    <h1>Preis: &bdquo;{{ preis.label }}&ldquo;</h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Label</th>
          <td>
            <input
              v-model="formData.label"
              type="text"
              :rqd="true"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>
        <tr>
          <th>Preis</th>
          <td>
            <input
              v-model="formData.preis"
              type="number"
              step="0.01"
              :rqd="true"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton class="button" :deactivated="!formDataAltered" @click="submit">
      Speichern
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'PreiseShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
  },
  props: ['preisId'],
  data() {
    return {
      formData: null,
      formErrors: null,
      formDataAltered: false,
    }
  },
  computed: {
    preis() {
      return this.$store.getters['preise/preis'](this.preisId)
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([store.dispatch('kategorien/getAll'), store.dispatch('preise/getAll')])
    next()
  },
  created() {
    this.formData = {
      label: this.preis.label,
      preis: this.preis.cent / 100,
    }
  },
  methods: {
    formDataChanged(/* event */) {
      /** // todo
       * validate event.target.value
       * if no errors, show saveChangesButton
       */
      this.formDataAltered = true
    },
    async submit() {
      try {
        await this.$store.dispatch('preise/update', {
          id: this.preis.id,
          formData: {
            label: this.formData.label,
            preis: this.formData.preis * 100,
          },
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
