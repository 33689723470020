import MenuAccountIcon from './MenuAccountIcon'
import MenuPasswordIcon from './MenuPasswordIcon'
import MenuPagesIcon from './MenuPagesIcon'
import MenuPageIcon from './MenuPageIcon'
import MenuLogoutIcon from './MenuLogoutIcon'
import MenuHelpIcon from './MenuHelpIcon'

export default {
  MenuHelpIcon,
  MenuLogoutIcon,
  MenuPageIcon,
  MenuPagesIcon,
  MenuPasswordIcon,
  MenuAccountIcon,
}
