<template>
  <input
    v-focus="af"
    :class="{ invalid: invalid }"
    :name="name"
    :type="type"
    :placeholder="ph"
    :value="value"
    :required="rqd"
    :style="`width: ${width}`"
    @input="updateValue($event.target.value)"
  />
</template>

<script>
export default {
  name: 'FormInput',
  directives: {
    focus: {
      inserted(el, binding) {
        if (binding.value) el.focus()
      },
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text', // text / email / password / ...
    },
    ph: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rqd: {
      type: Boolean,
      default: false,
    },
    af: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '16rem',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  height: 2.25rem;
  padding: 0.5rem;
  border: $input-border;
  border-radius: $input-border-radius;
  color: $input-col;
  background: $input-bg;
  font-size: 100%;
  transition: $border-trans;

  &::placeholder {
    color: $input-placeholder-col;
  }

  &:focus,
  &:active {
    border-color: $border-col-active;
  }

  &.invalid,
  &.invalid:focus,
  &.invalid:active {
    border-color: $border-col-danger;
  }
}
</style>
