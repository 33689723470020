import axios from 'axios'

const getDefaultState = () => ({
  preise: [],
})

const state = getDefaultState()

const getters = {
  preise: state => state.preise,
  preis: state => id => state.preise.find(p => p.id == id),
}

const mutations = {
  SET_ALL: (state, preise) => (state.preise = preise),

  SET_ONE: (state, preis) => {
    const target = state.preise.find(p => p.id == preis.id)
    Object.assign(target, preis)
  },

  ADD_ONE: (state, preis) => state.preise.push(preis),

  SET_POSITION: (state, { id, position }) => {
    const index = state.preise.findIndex(p => p.id == id)
    const removed = state.preise.splice(index, 1)[0]
    state.preise.splice(position - 1, 0, removed)
    let order = 1
    state.preise.forEach(p => (p.order = order++))
  },
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/preise'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  create: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/preise'
      const res = await axios.post(url, formData)
      commit('ADD_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/preise/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPosition: ({ commit, dispatch }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const url = `/preise/${payload.id}/set-position`
      const data = { position: payload.position }
      axios.post(url, data)
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite refreshen',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
