<template>
  <router-link class="link" :to="to">
    <arrow-left-icon class="arrow"></arrow-left-icon>
    <span><slot>Back</slot></span>
  </router-link>
</template>

<script>
import ArrowLeftIcon from '@/components/cmsIcons/ArrowLeftIcon'

export default {
  name: 'GoBack',
  components: {
    ArrowLeftIcon,
  },
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  display: flex;
  align-items: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
  font-weight: $weight-semibold;
  letter-spacing: $tracking-wide;
  color: $col-gray-700;
}

.arrow {
  height: 1.75rem;
  fill: $col-gray-700;
  margin-right: 0.75rem;
}

span {
  padding-bottom: 2px;
}
</style>
