<template>
  <div>
    <GoBack :to="{ name: 'WallpapersIndex' }">Wallpapers</GoBack>

    <h1>Wallpaper &rarr; bearbeiten</h1>

    <VForm @submit="submit" ref="form">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="file">File*</FormLabelInline>
          <input type="file" name="file" ref="file" required="required" />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

export default {
  name: 'WallpapersEdit',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
  },
  props: ['wallpaperId'],
  data() {
    return {
      formErrors: null,
    }
  },
  methods: {
    async submit() {
      try {
        let formData = new FormData()
        formData.append('file', this.$refs.file.files[0])

        await this.$store.dispatch('wallpapers/update', {
          id: this.wallpaperId,
          formData,
        })
        this.$router.push({ name: 'WallpapersIndex' })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
