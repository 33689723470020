<template>
  <router-link class="td-link" :to="to" tag="td">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'TdLink',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.td-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
