<template>
  <div class="form-row-fields">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormRowFields',
}
</script>

<style lang="scss" scoped>
.form-row-fields {
  display: flex;
  width: 100%;

  &:not(:first-child) {
    margin-top: 2rem;
  }
}
</style>
