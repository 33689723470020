<template>
  <div>
    <GoBack :to="{ name: 'ProdukteIndex', query: { kat: kategorie.id } }">
      Produkte: {{ kategorie.titel.plural }}
    </GoBack>

    <GoBack :to="{ name: 'ProduktgruppenShow', params: { produktgruppeId: produktgruppe.id } }">
      Produktgruppe: {{ produktgruppe.titel }}
    </GoBack>

    <GoBack :to="{ name: 'ProdukteShow', params: { produktId } }">
      Produkt:
      <template v-if="produktgruppe.produktfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in produkt.eigenschaften.filter(e =>
            produktgruppe.produktfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
    </GoBack>

    <h1>
      Produkt:
      <template v-if="produktgruppe.produktfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in produkt.eigenschaften.filter(e =>
            produktgruppe.produktfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
      &rarr; bearbeiten
    </h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="teaser">Teaser</FormLabelInline>
          <FormTextarea v-model="formData.teaser" name="teaser" width="32rem" :rows="2" />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="beschreibung">Beschreibung</FormLabelInline>
          <FormTextarea v-model="formData.beschreibung" name="beschreibung" width="32rem" />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="hinweis">Hinweis</FormLabelInline>
          <FormTextarea v-model="formData.hinweis" name="hinweis" width="32rem" />
        </FormRowFields>

        <!-- Optional Eigenschaften -->
        <FormRowFields>
          <FormLabelInline for="eigenschaften">
            Eigenschaften
          </FormLabelInline>
          <select
            class="select-eigenschaftsarten"
            name="eigenschaften"
            v-model="selectedEigenschaftsartId"
          >
            <option v-for="{ id, label } in remainingEigenschaftsarten" :key="id" :value="id">
              {{ label }}
            </option>
          </select>
          <select
            class="select-eigenschaften"
            v-if="selectedEigenschaftsartId"
            name="eigenschaften"
            @change="selectOptionalEigenschaft"
          >
            <option :value="null"></option>
            <option
              v-for="{ id, titel } in eigenschaftenForArt(selectedEigenschaftsartId)"
              :key="id"
              :value="id"
            >
              {{ titel }}
            </option>
          </select>
        </FormRowFields>

        <FormRowFields v-if="formData.eigenschaften.length > requiredEigenschaftsarten.length">
          <ul class="selected-list">
            <li v-for="id in selectedOptionalEigenschaften" :key="id">
              <span class="eigenschaftsart">
                {{
                  eigenschaftsarten.find(
                    art => art.id == eigenschaften.find(e => e.id == id).eigenschaftsartId
                  ).titel
                }}:
              </span>
              <span>{{ eigenschaften.find(e => e.id == id).titel }}</span>
              <span class="remove" @click="removeEigenschaft(id)">
                <XCircleIcon class="remove-icon" />
              </span>
            </li>
          </ul>
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="preisId">Preis*</FormLabelInline>
          <select name="preisId" v-model="formData.preisId">
            <option v-for="{ id, label, cent } in preise" :key="id" :value="id">
              {{ label }} ({{ formatPrice(cent) }})
            </option>
          </select>
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="quickbuy">Quickbuy</FormLabelInline>
          <VToggle name="quickbuy" :value="formData.quickbuy" @click="toggleQuickbuy" />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="neuFuer">Neu für (in Tagen)</FormLabelInline>
          <FormInput
            v-model="formData.neuFuer"
            name="neuFuer"
            type="number"
            step="1"
            width="3rem"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="variantenfilter">
            Varianten-Unterscheidungsmerkmale
          </FormLabelInline>
          <select
            class="select-eigenschaftsarten"
            name="variantenfilter"
            @change="selectVariantenfilter"
          >
            <option :value="null"></option>
            <option v-for="{ id, label } in remainingEigenschaftsarten" :key="id" :value="id">
              {{ label }}
            </option>
          </select>
        </FormRowFields>

        <FormRowFields v-if="formData.variantenfilter.length">
          <ul class="selected-list">
            <li v-for="id in formData.variantenfilter" :key="id">
              <span class="eigenschaftsart">
                {{ eigenschaftsarten.find(art => art.id == id).label }}
              </span>
              <span class="remove" @click="removeVariantenfilter(id)">
                <XCircleIcon class="remove-icon" />
              </span>
            </li>
          </ul>
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import VToggle from '@/components/global/Toggle'

import XCircleIcon from '@/components/cmsIcons/XCircleIcon'

export default {
  name: 'ProdukteEdit',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
    VToggle,
    XCircleIcon,
  },
  props: ['produktId'],
  data() {
    return {
      formData: null,
      formErrors: null,
      selectedEigenschaftsartId: null,
    }
  },
  computed: {
    produkt() {
      return store.getters['produkte/produkt'](this.produktId)
    },
    produktgruppe() {
      return store.getters['produktgruppen/produktgruppe'](this.produkt.produktgruppeId)
    },
    kategorie() {
      return store.getters['kategorien/kategorie'](this.produktgruppe.kategorieId)
    },
    eigenschaftsarten: () => store.getters['eigenschaftsarten/eigenschaftsarten'],
    eigenschaften: () => store.getters['eigenschaften/eigenschaften'],
    preise: () => store.getters['preise/preise'],
    requiredEigenschaftsarten() {
      return this.eigenschaftsarten.filter(art =>
        this.produktgruppe.produktfilter.map(f => f.id).includes(art.id)
      )
    },
    optionalEigenschaftsarten() {
      return this.eigenschaftsarten.filter(
        art => !this.produktgruppe.produktfilter.map(f => f.id).includes(art.id)
      )
    },
    remainingEigenschaftsarten() {
      const selectedEigenschaftsartenIds = this.formData.eigenschaften.map(
        selId => this.eigenschaften.find(e => e.id == selId).eigenschaftsartId
      )
      const usedEigenschaftsarten = [
        ...selectedEigenschaftsartenIds,
        ...this.formData.variantenfilter,
      ]
      return this.optionalEigenschaftsarten.filter(art => !usedEigenschaftsarten.includes(art.id))
    },
    selectedOptionalEigenschaften() {
      return this.formData.eigenschaften.filter(selId =>
        this.optionalEigenschaftsarten
          .map(art => art.id)
          .includes(this.eigenschaften.find(e => e.id == selId).eigenschaftsartId)
      )
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('produkte/getAll'),
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('kategorien/getAll'),
      store.dispatch('eigenschaftsarten/getAll'),
      store.dispatch('eigenschaften/getAll'),
      store.dispatch('preise/getAll'),
    ])
    next()
  },
  created() {
    this.formData = {
      teaser: this.produkt.teaser,
      beschreibung: this.produkt.beschreibung,
      hinweis: this.produkt.hinweis,
      eigenschaften: this.produkt.eigenschaften.map(e => e.id),
      preisId: this.produkt.preis.id,
      quickbuy: this.produkt.quickbuy,
      neuFuer: this.produkt.neuFuer,
      variantenfilter: this.produkt.variantenfilter.map(p => p.id),
    }
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    eigenschaftenForArt(artId) {
      return store.getters['eigenschaften/eigenschaftenForEigenschaftsart'](artId)
    },
    selectRequiredEigenschaft(event) {
      const id = event.target.value
      const artId = this.eigenschaften.find(e => e.id == id).eigenschaftsartId
      const index = this.formData.eigenschaften.findIndex(
        selected => this.eigenschaften.find(e => e.id == selected).eigenschaftsartId == artId
      )
      this.formData.eigenschaften[index] = id
    },
    selectOptionalEigenschaft(event) {
      this.formData.eigenschaften.push(event.target.value)
      this.selectedEigenschaftsartId = null
      event.target.value = null
    },
    removeEigenschaft(id) {
      this.formData.eigenschaften.splice(
        this.formData.eigenschaften.findIndex(selId => selId == id),
        1
      )
    },
    toggleQuickbuy(newValue) {
      this.formData.quickbuy = newValue
    },
    selectVariantenfilter(event) {
      // need parseInt, because event.target.value returns a string
      this.formData.variantenfilter.push(parseInt(event.target.value))
    },
    removeVariantenfilter(id) {
      this.formData.variantenfilter.splice(
        this.formData.variantenfilter.findIndex(filter => filter == id),
        1
      )
    },
    async submit() {
      try {
        await this.$store.dispatch('produkte/update', {
          id: this.produktId,
          formData: this.formData,
        })
        this.$router.push({
          name: 'ProdukteShow',
          params: { produktId: this.produktId },
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.select-eigenschaftsarten,
.select-eigenschaften {
  width: 15rem;
}

.select-eigenschaften {
  margin-left: 2rem;
}

.selected-list {
  margin-left: 16rem;

  li:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.eigenschaftsart {
  margin-right: 0.25rem;
  font-weight: $weight-semibold;
}

.remove {
  display: inline-block;
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}

.remove-icon {
  display: inline-block;
  height: 1.25rem;
  transform: translateY(0.25rem);
  fill: $col-red-500;
}
</style>
