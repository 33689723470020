import axios from 'axios'

const getDefaultState = () => ({
  bestellungen: [],
})

const state = getDefaultState()

const getters = {
  bestellungen: state => state.bestellungen,
  bestellung: state => bestellnummer =>
    state.bestellungen.find(best => best.bestellnummer == bestellnummer),
}

const mutations = {
  SET_ALL: (state, bestellungen) => (state.bestellungen = bestellungen),

  SET_ONE: (state, bestellung) => {
    const target = state.bestellungen.find(best => best.bestellnummer == bestellung.bestellnummer)
    Object.assign(target, bestellung)
  },

  ADD_ONE: (state, bestellung) => state.bestellungen.push(bestellung),
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/bestellungen'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  create: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/bestellungen'
      const res = await axios.post(url, formData)
      commit('ADD_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { bestellnummer, formData }) => {
    try {
      const url = `/bestellungen/${bestellnummer}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setBezahlt: async ({ commit, dispatch }, bestellnummer) => {
    try {
      const url = `/bestellungen/${bestellnummer}/set-bezahlt`
      const res = await axios.post(url)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setVersandt: async ({ commit, dispatch }, bestellnummer) => {
    try {
      const url = `/bestellungen/${bestellnummer}/set-versandt`
      const res = await axios.post(url)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setAbgebrochen: async ({ commit, dispatch }, bestellnummer) => {
    try {
      const url = `/bestellungen/${bestellnummer}/set-abgebrochen`
      const res = await axios.post(url)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setProblem: async ({ commit, dispatch }, bestellnummer) => {
    try {
      const url = `/bestellungen/${bestellnummer}/set-problem`
      const res = await axios.post(url)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
