<template>
  <div>
    <GoBack :to="{ name: 'WarenbewegungenIndex' }">Warenbewegungen</GoBack>

    <h1>Warenbewegung &rarr; anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="warenbewegungsartId">Warenbewegungsart*</FormLabelInline>
          <select name="warenbewegungsartId" v-model="formData.warenbewegungsartId">
            <option v-for="{ id, label } in warenbewegungsarten" :key="id" :value="id">
              {{ label }}
            </option>
          </select>
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="veraenderung">Veränderung*</FormLabelInline>
          <FormInput
            v-model="formData.veraenderung"
            name="veraenderung"
            type="number"
            step="1"
            :rqd="true"
          />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

export default {
  name: 'WarenbewegungenCreate',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
  },
  props: ['var'],
  data() {
    return {
      formData: {
        warenbewegungsartId: null,
        veraenderung: 0,
        varianteId: this.var,
      },
      formErrors: null,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('warenbewegungsarten/getAll'),
      store.dispatch('varianten/getAll'),
    ])
    next()
  },
  computed: {
    warenbewegungsarten: () => store.getters['warenbewegungsarten/warenbewegungsarten'],
    variante() {
      return store.getters['varianten/variante'](this.var)
    },
  },
  methods: {
    async submit() {
      try {
        await store.dispatch('warenbewegungen/create', this.formData)
        store.dispatch('varianten/updateBestand', {
          id: this.var,
          veraenderung: this.formData.veraenderung,
        })
        this.$router.push({ name: 'ProdukteShow', params: { produktId: this.variante.produktId } })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
