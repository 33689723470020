<template>
  <div>
    <h1>Wallpapers</h1>

    <ListTable v-if="wallpapers">
      <thead>
        <tr>
          <th>Label</th>
          <th>Bild</th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="{ id, label, conversions } in wallpapers" :key="id">
          <TdLink :to="{ name: 'WallpapersEdit', params: { wallpaperId: id } }">
            {{ label }}
          </TdLink>
          <td>
            <ResponsiveImage class="img" :conversions="conversions" sizes="240px" :alt="label" />
          </td>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'
import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'WallpapersIndex',
  components: {
    ...TableComponents,
    ResponsiveImage,
  },
  computed: {
    wallpapers: () => store.getters['wallpapers/wallpapers'],
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('wallpapers/getAll')
    next()
  },
}
</script>

<style lang="scss" scoped>
.img {
  height: 4rem;
}
</style>
