var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Produkte")]),_c('label',{attrs:{"for":"kategorieFilter"}},[_vm._v("Kategorie: ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.kategorieId),expression:"kategorieId"}],attrs:{"name":"kategorieFilter"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.kategorieId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.kategorien),function(ref){
var id = ref.id;
var titel = ref.titel;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(titel.plural)+" ")])}),0),_c('VSpacer',{attrs:{"mode":"small"}}),_c('VButton',{attrs:{"type":"link","to":{ name: 'ProduktgruppenCreate', query: { kat: _vm.kategorieId } }}},[_vm._v(" NEUE PRODUKTGRUPPE ")]),_c('VSpacer',{attrs:{"mode":"small"}}),_c('ListTable',[_c('thead',[_c('tr',[_c('th',[_vm._v("Produktgruppe")]),_c('th',[_vm._v("Produkt")]),_c('th',[_c('VisibleIcon',{staticClass:"table-icon"})],1),_c('th',[_c('OrderIcon',{staticClass:"table-icon"})],1)])]),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event)}}},_vm._l((_vm.filteredProdukte),function(ref,i){
var id = ref.id;
var produktgruppeId = ref.produktgruppeId;
var eigenschaften = ref.eigenschaften;
var publiziert = ref.publiziert;
return _c('tr',{key:id},[_c('TdLink',{attrs:{"to":{ name: 'ProduktgruppenShow', params: { produktgruppeId: produktgruppeId } }}},[_vm._v(" "+_vm._s(_vm.findProduktgruppe(produktgruppeId).titel)+" ")]),_c('TdLink',{attrs:{"to":{ name: 'ProdukteShow', params: { produktId: id } }}},[(_vm.findProduktgruppe(produktgruppeId).produktfilter.length)?_vm._l((_vm.findProduktgruppe(produktgruppeId).produktfilter),function(filter){return _c('span',{key:filter.id,staticClass:"inline-list"},[_vm._v(" "+_vm._s(filter.titel)+": "+_vm._s(eigenschaften.find(function (e) { return e.eigenschaftsartId == filter.id; }).titel)+" ")])}):[_vm._v("<Default>")]],2),_c('td',[_c('TableToggle',{attrs:{"name":"publizieren","value":publiziert},on:{"click":function($event){return _vm.togglePubliziert($event, id)}}})],1),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event })}}})],1)],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }