<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>X</slot>
    </title>
    <defs>
      <mask id="x">
        <circle cx="32" cy="32" r="32" fill="white" />
        <path
          d="M44,39.78a2,2,0,0,1,0,2.83L42.61,44a2,2,0,0,1-2.83,0L32,36.24,24.22,44a2,2,0,0,1-2.83,0L20,42.61a2,2,0,0,1,0-2.83L27.76,32,20,24.22a2,2,0,0,1,0-2.83L21.39,20a2,2,0,0,1,2.83,0L32,27.76,39.78,20a2,2,0,0,1,2.83,0L44,21.39a2,2,0,0,1,0,2.83L36.24,32Z"
          fill="black"
        />
      </mask>
    </defs>
    <rect height="64" width="64" mask="url(#x)"></rect>
  </svg>
</template>

<script>
export default {
  name: 'XCircleIcon',
}
</script>
