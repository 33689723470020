<template>
  <div class="form-row-errors">
    <span class="spacer" :style="`width: ${spaceLeft}`"></span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormRowErrors',
  props: {
    spaceLeft: {
      type: String,
      default: '16rem',
    },
  },
}
</script>

<style lang="scss" scoped>
.form-row-errors {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: $col-red-500;
}
</style>
