import axios from 'axios'

const getDefaultState = () => ({
  produktgruppen: [],
})

const state = getDefaultState()

const getters = {
  produktgruppen: state => state.produktgruppen,
  produktgruppe: state => id => state.produktgruppen.find(gru => gru.id == id),
}

const mutations = {
  SET_ALL: (state, produktgruppen) => (state.produktgruppen = produktgruppen),

  SET_ONE: (state, produktgruppe) => {
    const target = state.produktgruppen.find(gru => gru.id == produktgruppe.id)
    Object.assign(target, produktgruppe)
  },

  ADD_ONE: (state, produktgruppe) => state.produktgruppen.push(produktgruppe),
  // todo This is not exactly the right place
  // should be added as last entry of produktegruppen with same kategorie
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/produktgruppen'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  create: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/produktgruppen'
      const res = await axios.post(url, formData)
      commit('ADD_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/produktgruppen/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
