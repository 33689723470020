<template>
  <picture>
    <!--
      http://probablyprogramming.com/2009/03/15/the-tiniest-gif-ever
      https://swimburger.net/blog/web/web-performance-prevent-wasteful-hidden-image-requests
     -->
    <source
      v-for="{ type, srcset } in srcsets"
      :key="type"
      :srcset="srcset"
      :sizes="sizes"
      :type="`image/${type}`"
      :media="media"
    />
    <!--
      If image will not be visible for user, prevent unnecessary download.
      Instead show tiny, transparent, 1x1 px inline-image.
     -->
    <img
      class="responsive-image"
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      :alt="alt"
      :style="css"
    />
  </picture>
</template>

<script>
export default {
  name: 'ResponsiveImage',
  props: {
    conversions: {
      type: Array,
      required: true,
      default: () => [],
    },
    sizes: {
      type: String,
      required: true,
    },
    media: {
      // only load image when this media query is fulfilled
      type: String,
      default: '',
    },
    alt: {
      type: String,
      required: true,
    },
    css: {
      type: String,
      default: '',
    },
  },
  computed: {
    srcsets() {
      const typeGroups = this.conversions.reduce((typeGroup, conversion) => {
        typeGroup.hasOwnProperty(conversion.type)
          ? typeGroup[conversion.type].push(conversion)
          : (typeGroup[conversion.type] = [conversion])
        return typeGroup
      }, {})
      return Object.entries(typeGroups).map(([type, conversions]) => {
        return {
          type,
          srcset: conversions
            .sort((a, b) => +a.width - +b.width)
            .map(conversion => conversion.url + ' ' + conversion.width + 'w')
            .join(', '),
        }
      })
    },
  },
}
</script>

<style scoped>
.responsive-image {
  display: block;
  position: relative;
}
</style>
