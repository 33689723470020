<template>
  <span :style="sizeVar"></span>
</template>

<script>
export default {
  name: 'DragHandle',
  props: {
    size: {
      default: '1rem',
      type: String,
    },
  },
  computed: {
    sizeVar() {
      return {
        '--size': this.size,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
span {
  display: inline-block;
  position: relative;
  height: var(--size);
  width: var(--size);
  background-image: radial-gradient(
      circle at calc(var(--size) * 0.3125) calc(var(--size) * 0.125),
      $col-gray-200,
      $col-gray-200 calc(var(--size) * 0.09375),
      transparent calc(var(--size) * 0.09375),
      transparent
    ),
    radial-gradient(
      circle at calc(var(--size) * 0.3125) calc(var(--size) * 0.5),
      $col-gray-200,
      $col-gray-200 calc(var(--size) * 0.09375),
      transparent calc(var(--size) * 0.09375),
      transparent
    ),
    radial-gradient(
      circle at calc(var(--size) * 0.3125) calc(var(--size) * 0.875),
      $col-gray-200,
      $col-gray-200 calc(var(--size) * 0.09375),
      transparent calc(var(--size) * 0.09375),
      transparent
    ),
    radial-gradient(
      circle at calc(var(--size) * 0.6875) calc(var(--size) * 0.125),
      $col-gray-200,
      $col-gray-200 calc(var(--size) * 0.09375),
      transparent calc(var(--size) * 0.09375),
      transparent
    ),
    radial-gradient(
      circle at calc(var(--size) * 0.6875) calc(var(--size) * 0.5),
      $col-gray-200,
      $col-gray-200 calc(var(--size) * 0.09375),
      transparent calc(var(--size) * 0.09375),
      transparent
    ),
    radial-gradient(
      circle at calc(var(--size) * 0.6875) calc(var(--size) * 0.875),
      $col-gray-200,
      $col-gray-200 calc(var(--size) * 0.09375),
      transparent calc(var(--size) * 0.09375),
      transparent
    );
}
</style>
