<template>
  <div>
    <GoBack
      :to="{
        name: 'EigenschaftsartenShow',
        params: { eigenschaftsartId: eigenschaft.eigenschaftsartId },
      }"
    >
      Eigenschaftsart
    </GoBack>

    <h1>Eigenschaft: &bdquo;{{ eigenschaft.titel }}&ldquo;</h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Titel</th>
          <td>
            <input type="text" v-model="formData.titel" @keyup="formDataChanged($event)" />
          </td>
        </tr>
        <tr>
          <th>Beschreibung</th>
          <td>
            <textarea
              type="text"
              v-model="formData.beschreibung"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton class="button" :deactivated="!formDataAltered" @click="submit">
      Speichern
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'EigenschaftenShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
  },
  props: ['eigenschaftId'],
  data() {
    return {
      formData: null,
      formErrors: null,
      formDataAltered: false,
    }
  },
  computed: {
    eigenschaft() {
      return store.getters['eigenschaften/eigenschaft'](this.eigenschaftId)
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('eigenschaftsarten/getAll'),
      store.dispatch('eigenschaften/getAll'),
    ])
    next()
  },
  created() {
    this.formData = {
      titel: this.eigenschaft.titel,
      beschreibung: this.eigenschaft.beschreibung,
    }
  },
  methods: {
    formDataChanged(/* event */) {
      /** // todo
       * validate event.target.value
       * if no errors, show saveChangesButton
       */
      this.formDataAltered = true
    },
    async submit() {
      try {
        await this.$store.dispatch('eigenschaften/update', {
          id: this.eigenschaft.id,
          formData: this.formData,
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
