<template>
  <router-link class="link" :to="to">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411 411">
      <title>{{ hoverText }}</title>
      <g>
        <path
          d="M274.9,87.1,69.4,289.9a8.6,8.6,0,0,0-2.1,3.4L36.8,370.9c-1,3,2.7,6.7,5.7,5.7l78.9-30a7.4,7.4,0,0,0,2.9-1.8l36.7-36.3,169-166.8,3.3-3.2L278.2,83.8Zm26.8,43.6c1.3-1.2,3.6-.9,5.2.7s1.9,3.9.7,5.2l-187.4,185a2.9,2.9,0,0,1-2.5.7,4.9,4.9,0,0,1-2.7-1.4c-1.6-1.6-1.9-3.9-.7-5.2Zm-21.6-21.4c1.3-1.2,3.6-.9,5.2.7s1.9,3.9.7,5.2L98.6,300a2.9,2.9,0,0,1-2.5.7,4.9,4.9,0,0,1-2.7-1.4c-1.6-1.6-1.9-3.9-.7-5.2Z"
        />
        <path
          d="M372.2,61.4,356,45.4c-10.8-10.7-29.8-9.1-42.5,3.5L286,76.2l55.3,54.6,27.5-27.2C381.4,91,383,72.1,372.2,61.4Z"
        />
      </g>
    </svg>
  </router-link>
</template>

<script>
export default {
  name: 'TableButtonEdit',
  props: {
    to: {
      type: [String, Object],
      default: '/',
    },
    hoverText: {
      type: String,
      default: 'Edit',
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  height: 1.5rem;
  cursor: pointer;

  svg {
    width: 1.5rem;
    fill: $col-gray-900;
    transition: transform ease 125ms, fill ease 125ms;
  }

  &:focus,
  &:hover {
    svg {
      transform: scale(1.25);
      fill: $col-primary-500;
    }
  }
}
</style>
