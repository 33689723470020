<template>
  <div>
    <GoBack :to="{ name: 'KategorienIndex' }">Kategorien</GoBack>

    <h1>Kategorie: &bdquo;{{ kategorie.titel.plural }}&ldquo;</h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Titel (singular)</th>
          <td>
            <input type="text" v-model="formData.titelSingular" @keyup="formDataChanged($event)" />
          </td>
        </tr>
        <tr>
          <th>Titel (plural)</th>
          <td>
            <input type="text" v-model="formData.titelPlural" @keyup="formDataChanged($event)" />
          </td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton class="button" :deactivated="!formDataAltered" @click="submit">
      Speichern
    </VButton>

    <VSpacer />

    <VToggle name="publizieren" :value="kategorie.publiziert" @click="togglePubliziert">
      publizieren
    </VToggle>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'
import VToggle from '@/components/global/Toggle'

export default {
  name: 'KategorienShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
    VToggle,
  },
  data() {
    return {
      formData: null,
      formErrors: null,
      formDataAltered: false,
    }
  },
  computed: {
    kategorie() {
      return store.getters['kategorien/kategorie'](this.kategorieId)
    },
  },
  props: ['kategorieId'],
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('kategorien/getAll')
    next()
  },
  created() {
    this.formData = {
      titelSingular: this.kategorie.titel.singular,
      titelPlural: this.kategorie.titel.plural,
    }
  },
  methods: {
    formDataChanged(/* event */) {
      /** // todo
       * validate event.target.value
       * if no errors, show saveChangesButton
       */
      this.formDataAltered = true
    },
    async submit() {
      try {
        await this.$store.dispatch('kategorien/update', {
          id: this.kategorie.id,
          formData: this.formData,
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
    togglePubliziert(newValue) {
      if (newValue) {
        this.$store.dispatch('kategorien/publish', this.kategorie.id)
      } else {
        this.$store.dispatch('kategorien/unpublish', this.kategorie.id)
      }
    },
  },
}
</script>
