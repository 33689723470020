<template>
  <div>
    <GoBack :to="{ name: 'KategorienIndex' }">Kategorien</GoBack>

    <h1>Kategorie &rarr; anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="titelSingular">Titel, singular*</FormLabelInline>
          <FormInput
            v-model="formData.titelSingular"
            name="titelSingular"
            type="text"
            placeholder="Grußkarte"
            :af="true"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="titelPlural">Titel, plural*</FormLabelInline>
          <FormInput
            v-model="formData.titelPlural"
            name="titelPlural"
            type="text"
            placeholder="Grußkarten"
            :rqd="true"
          />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

export default {
  name: 'KategorienCreate',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
  },
  data() {
    return {
      formData: {
        titelSingular: '',
        titelPlural: '',
      },
      formErrors: null,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('kategorien/getAll')
    next()
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('kategorien/create', this.formData)
        this.$router.push({ name: 'KategorienIndex' })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
