import VForm from './Form'
import FormBoxFields from './FormBoxFields'
import FormBoxButtons from './FormBoxButtons'
import FormRowFields from './FormRowFields'
import FormRowEditor from './FormRowEditor'
import FormRowButtons from './FormRowButtons'
import FormRowErrors from './FormRowErrors'
import FormLabelInline from './FormLabelInline'
import FormInput from './FormInput'
import FormTextarea from './FormTextarea'
import FormError from './FormError'
import FormFieldError from './FormFieldError'

export default {
  VForm,
  FormBoxFields,
  FormBoxButtons,
  FormRowFields,
  FormRowEditor,
  FormRowButtons,
  FormRowErrors,
  FormLabelInline,
  FormInput,
  FormTextarea,
  FormError,
  FormFieldError,
}
