<template>
  <div class="form-row-buttons">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormRowButtons',
}
</script>

<style lang="scss" scoped>
.form-row-buttons {
  width: 100%;
  text-align: center;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  ::v-deep {
    > * {
      margin: 1rem;
    }
  }
}
</style>
