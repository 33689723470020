import axios from 'axios'
import Vue from 'vue'

const getDefaultState = () => ({
  varianten: [],
})

const state = getDefaultState()

const getters = {
  varianten: state => state.varianten,
  variantenForProdukt: state => id => state.varianten.filter(variante => variante.produktId == id),
  variante: state => id => state.varianten.find(variante => variante.id == id),
}

const mutations = {
  SET_ALL: (state, varianten) => (state.varianten = varianten),

  SET_ONE: (state, variante) => {
    const target = state.varianten.find(vari => vari.id == variante.id)
    Object.assign(target, variante)
  },

  ADD_ONE: (state, variante) => state.varianten.push(variante),
  // todo This is not exactly the right place
  // should be added as last entry of varianten with same produkt

  SET_PUBLISHED: (state, id) => {
    const target = state.varianten.find(variante => variante.id == id)
    Vue.set(target, 'publiziert', true)
  },

  SET_PRIVATE: (state, id) => {
    const target = state.varianten.find(variante => variante.id == id)
    Vue.set(target, 'publiziert', false)
  },

  UPDATE_BESTAND: (state, { id, veraenderung }) => {
    const target = state.varianten.find(variante => variante.id == id)
    Vue.set(target.bestand, 'real', target.bestand.real + veraenderung)
  },

  REMOVE_ONE: (state, id) => {
    const index = state.varianten.findIndex(variante => variante.id == id)
    state.varianten.splice(index, 1)
  },
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/varianten'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  create: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/varianten'
      const res = await axios.post(url, formData)
      commit('ADD_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/varianten/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  makeDefault: async ({ dispatch, getters }, id) => {
    dispatch(
      'produkte/setDefaultVarianteId',
      {
        varianteId: id,
        produktId: getters['variante'](id).produktId,
      },
      { root: true }
    )
    try {
      const url = `/varianten/${id}/make-default`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'neuen Default gesetzt',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Default setzen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  publish: async ({ commit, dispatch }, id) => {
    // commit('SET_PUBLISHED', id)
    try {
      const url = `/varianten/${id}/publish`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'publiziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'publizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  unpublish: async ({ commit, dispatch }, id) => {
    // commit('SET_PRIVATE', id)
    try {
      const url = `/varianten/${id}/unpublish`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'depubliziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'depublizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  updateBestand: ({ commit }, payload) => {
    commit('UPDATE_BESTAND', payload)
  },

  archive: async ({ commit, dispatch }, id) => {
    commit('REMOVE_ONE', id)
    try {
      const url = `/varianten/${id}/archive`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'archiviert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'archivieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
