<template>
  <div>
    <GoBack :to="{ name: 'EigenschaftsartenIndex' }">Eigenschaftsarten</GoBack>

    <h1>Eigenschaftsart &rarr; anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="label">(internes) Label*</FormLabelInline>
          <FormInput
            v-model="formData.label"
            name="label"
            type="text"
            placeholder="Sackerl - Material"
            :af="true"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="titel">Titel (öffentlich)*</FormLabelInline>
          <FormInput
            v-model="formData.titel"
            name="titel"
            type="text"
            placeholder="Material"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="beschreibung">Beschreibung</FormLabelInline>
          <FormTextarea v-model="formData.beschreibung" name="beschreibung" width="32rem" />
        </FormRowFields>
      </FormBoxFields>
      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

export default {
  name: 'EigenschaftsartenCreate',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
  },
  data() {
    return {
      formData: {
        label: '',
        titel: '',
        beschreibung: '',
      },
      formErrors: null,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('eigenschaftsarten/getAll')
    next()
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('eigenschaftsarten/create', this.formData)
        this.$router.push({ name: 'EigenschaftsartenIndex' })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
