<template>
  <div>
    <GoBack :to="{ name: 'ProdukteIndex', query: { kat: kategorie.id } }">
      Produkte: {{ kategorie.titel.plural }}
    </GoBack>

    <GoBack :to="{ name: 'ProduktgruppenShow', params: { produktgruppeId: produktgruppe.id } }">
      Produktgruppe: {{ produktgruppe.titel }}
    </GoBack>

    <h1>
      Produkt:
      <template v-if="produktgruppe.produktfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in produkt.eigenschaften.filter(e =>
            produktgruppe.produktfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
    </h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Teaser</th>
          <td>
            <textarea type="text" v-model="formData.teaser" @keyup="formDataChanged($event)" />
          </td>
        </tr>

        <tr>
          <th>Beschreibung</th>
          <td>
            <textarea
              type="text"
              v-model="formData.beschreibung"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>

        <tr>
          <th>Hinweis</th>
          <td>
            <textarea type="text" v-model="formData.hinweis" @keyup="formDataChanged($event)" />
          </td>
        </tr>

        <template v-if="produkt.eigenschaften.length">
          <tr v-for="({ id, eigenschaftsartId, titel }, i) in produkt.eigenschaften" :key="id">
            <th><template v-if="i == 0">Eigenschaften</template></th>
            <td>
              {{ eigenschaftsarten.find(art => art.id == eigenschaftsartId).titel }}:
              {{ titel }}
            </td>
          </tr>
        </template>
        <tr v-else>
          <th>Eigenschaften</th>
          <td>&mdash;</td>
        </tr>

        <tr>
          <th>Preis</th>
          <td>{{ formatPrice(produkt.preis.cent) }}</td>
        </tr>

        <tr>
          <th>Quickbuy</th>
          <td>
            <VToggle name="quickbuy" :value="formData.quickbuy" @click="toggleQuickbuy"></VToggle>
          </td>
        </tr>

        <tr>
          <th>Neu bis</th>
          <td>
            <template v-if="produkt.neu">
              {{ produkt.neuBis }} (noch {{ produkt.neuFuer }} Tage)
            </template>
            <template v-else>&mdash;</template>
          </td>
        </tr>

        <template v-if="produkt.variantenfilter.length">
          <tr v-for="({ id, titel }, i) in produkt.variantenfilter" :key="id">
            <th><template v-if="i == 0">Variantenfilter</template></th>
            <td>{{ titel }}</td>
          </tr>
        </template>
        <tr v-else>
          <th>Variantenfilter</th>
          <td>&mdash;</td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton :deactivated="!formDataAltered" @click="submit">
      Speichern
    </VButton>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'ProdukteEdit', params: { produktId } }">
      Bearbeiten
    </VButton>

    <VSpacer />

    <VToggle name="publizieren" :value="produkt.publiziert" @click="toggleProduktPubliziert">
      publizieren
    </VToggle>

    <VSpacer />

    <h2>Varianten</h2>

    <VButton
      class="button"
      type="link"
      :to="{ name: 'VariantenCreate', query: { prod: produktId } }"
    >
      NEU
    </VButton>

    <VSpacer mode="small" />

    <ListTable v-if="varianten">
      <thead>
        <tr>
          <th>Variante</th>
          <th>Preis</th>
          <th>Bestand</th>
          <th>...versteckt</th>
          <th>...Warnung</th>
          <th>Default</th>
          <th><VisibleIcon class="table-icon" /></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="{ id, titel, eigenschaften, preis, publiziert, bestand } in varianten" :key="id">
          <TdLink :to="{ name: 'VariantenShow', params: { varianteId: id } }">
            <template v-if="produkt.variantenfilter.length">
              <span class="inline-list" v-for="filter in produkt.variantenfilter" :key="filter.id">
                {{ filter.titel }}:
                {{ eigenschaften.find(e => e.eigenschaftsartId == filter.id).titel }}
              </span>
            </template>
            <template v-else>&lt;Default&gt;</template>
          </TdLink>

          <td>
            <template v-if="preis">{{ formatPrice(preis.cent) }}</template>
            <template v-else>&mdash;</template>
          </td>

          <td>
            {{ bestand.real }}
            <TableButtonEdit
              type="link"
              :to="{ name: 'WarenbewegungenCreate', query: { var: id } }"
            />
          </td>

          <td>{{ bestand.versteckt }}</td>

          <td>{{ bestand.schwelle }}</td>

          <td>
            <span
              class="make-default"
              v-if="produkt.defaultVarianteId != id"
              @click="makeDefault(id)"
            >
              DEFAULT
            </span>
            <span v-else class="is-default">DEFAULT</span>
          </td>

          <td>
            <TableToggle
              name="publizieren"
              :value="publiziert"
              @click="toggleVariantePubliziert($event, id)"
            ></TableToggle>
          </td>
        </tr>
      </tbody>
    </ListTable>

    <VSpacer />

    <h2>Produktbilder</h2>

    <VButton
      class="button"
      type="link"
      :to="{ name: 'ProduktbilderCreate', query: { prod: produktId } }"
    >
      NEU
    </VButton>

    <VSpacer mode="small" />

    <ListTable v-if="produktbilderForProdukt">
      <thead>
        <tr>
          <th>Bild</th>
          <th>Label</th>
          <th>Rahmen</th>
          <th><OrderIcon class="table-icon" /></th>
          <th></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="({ id, label, rahmen, bild }, i) in produktbilderForProdukt" :key="id">
          <td>
            <ResponsiveImage
              class="img"
              :conversions="bild.conversions"
              sizes="240px"
              :alt="label"
            />
          </td>
          <td>{{ label }}</td>
          <td>{{ rahmen ? 'ja' : 'nein' }}</td>
          <td>
            <TableOrder :order="i + 1" @submit="setOrder({ id, position: $event })" />
          </td>
          <td>
            <TableButtonDelete hoverText="Löschen" @click="deleteProduktbild(id)" />
          </td>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'
import VToggle from '@/components/global/Toggle'
import ResponsiveImage from '@/components/ResponsiveImage'

import VisibleIcon from '@/components/cmsIcons/VisibleIcon'
import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'ProdukteShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
    VToggle,
    ResponsiveImage,
    VisibleIcon,
    OrderIcon,
  },
  props: ['produktId'],
  data() {
    return {
      formData: null,
      formErrors: null,
      formDataAltered: false,
    }
  },
  computed: {
    // produkt() {
    //   return store.getters['produkte/produkt'](this.produktId)
    // },
    produkt() {
      return store.getters['produkte/onlyProdukt']
    },
    produktgruppe() {
      return store.getters['produktgruppen/produktgruppe'](this.produkt.produktgruppeId)
    },
    kategorie() {
      return store.getters['kategorien/kategorie'](this.produktgruppe.kategorieId)
    },
    // varianten() {
    //   return store.getters['varianten/variantenForProdukt'](this.produktId)
    // },
    varianten: vm => vm.produkt.varianten,
    eigenschaftsarten: () => store.getters['eigenschaftsarten/eigenschaftsarten'],
    // produktbilder: () => store.getters['produktbilder/produktbilder'],
    // produktbilderForProdukt() {
    //   return this.produktbilder.filter(bild => bild.produktId == this.produktId)
    // },
    produktbilderForProdukt: vm => vm.produkt.produktbilder,
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('produkte/getOne', to.params.produktId),
      // store.dispatch('varianten/getAll'),
      store.dispatch('kategorien/getAll'),
      store.dispatch('eigenschaftsarten/getAll'),
      // store.dispatch('produktbilder/getAll'),
    ])
    next()
  },
  created() {
    this.formData = {
      teaser: this.produkt.teaser,
      beschreibung: this.produkt.beschreibung,
      hinweis: this.produkt.hinweis,
      quickbuy: this.produkt.quickbuy,
    }
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    formDataChanged(/* event */) {
      /**
       * validate event.target.value
       * if no errors, show saveChangesButton
       */
      this.formDataAltered = true
    },
    toggleQuickbuy(newValue) {
      this.formData.quickbuy = newValue
      this.formDataChanged()
    },
    async submit() {
      try {
        await this.$store.dispatch('produkte/update', {
          id: this.produktId,
          formData: this.formData,
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
    toggleProduktPubliziert(newValue) {
      if (newValue) {
        this.$store.dispatch('produkte/publish', this.produktId)
      } else {
        this.$store.dispatch('produkte/unpublish', this.produktId)
      }
    },
    makeDefault(varianteId) {
      this.$store.dispatch('varianten/makeDefault', varianteId)
    },
    toggleVariantePubliziert(newValue, varianteId) {
      if (newValue) {
        this.$store.dispatch('varianten/publish', varianteId)
      } else {
        this.$store.dispatch('varianten/unpublish', varianteId)
      }
    },
    setOrder(payload) {
      this.$store.dispatch('produktbilder/setPosition', payload)
    },
    onOrderEnd(event) {
      const id = this.produktbilderForProdukt[event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch('produktbilder/setPosition', { id, position })
    },
    deleteProduktbild(produktbildId) {
      this.$store.dispatch('produktbilder/delete', produktbildId)
    },
  },
}
</script>

<style lang="scss" scoped>
.inline-list:not(:last-of-type)::after {
  display: inline-block;
  content: '|';
}

.table-icon {
  height: 2rem;
  fill: $col-white;
}

.make-default,
.is-default {
  padding: 0.0625rem 0.5rem 0.125rem;
  color: white;
  font-size: 0.875rem;
  font-weight: $weight-semibold;
  letter-spacing: $tracking-wide;
  border-radius: 4px;
}

.make-default {
  background: $col-gray-200;
  cursor: pointer;
}

.is-default {
  background: $col-primary-500;
}

/deep/ .img img {
  width: 12rem;
}
</style>
