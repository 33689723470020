<template>
  <button class="button" type="button" @click="handleClick">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411 411">
      <title>{{ hoverText }}</title>
      <path
        d="M312.1,87.9H239.4V70a6.9,6.9,0,0,0-6.9-6.9H178.5a6.9,6.9,0,0,0-6.9,6.9V87.9H98.9A6.9,6.9,0,0,0,92,94.8v24.5a6.9,6.9,0,0,0,6.9,6.9H312.1a6.9,6.9,0,0,0,6.9-6.9V94.8A6.9,6.9,0,0,0,312.1,87.9Z"
      />
      <path
        d="M289.6,140.1H121.5a9.6,9.6,0,0,0-9.8,9.4l21.9,189a9.6,9.6,0,0,0,9.8,9.4H267.7a9.6,9.6,0,0,0,9.8-9.4l21.9-189A9.6,9.6,0,0,0,289.6,140.1ZM165,314.5h-.3a4.5,4.5,0,0,1-4.5-4.2l-9.4-131.2a4.5,4.5,0,0,1,8.9-.6l9.4,131.2A4.5,4.5,0,0,1,165,314.5Zm44.8-4.3a4.5,4.5,0,0,1-8.9,0V178.6a4.5,4.5,0,0,1,8.9,0Zm49.6-131.1L250,310.3a4.5,4.5,0,0,1-4.5,4.2h-.3a4.5,4.5,0,0,1-4.1-4.8l9.4-131.2a4.5,4.5,0,0,1,8.9.6Z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'TableButtonDelete',
  props: {
    hoverText: {
      type: String,
      default: 'Delete',
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  height: 1.5rem;
  cursor: pointer;

  svg {
    width: 1.5rem;
    fill: $col-gray-900;
    transition: transform ease 125ms, fill ease 125ms;
  }

  &:focus,
  &:hover {
    svg {
      transform: scale(1.25);
      fill: $col-red-500;
    }
  }
}
</style>
