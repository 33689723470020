<template>
  <table class="list-table">
    <slot></slot>
  </table>
</template>

<script>
export default {
  name: 'ListTable',
}
</script>

<style lang="scss" scoped>
.list-table {
  --border-spacing: 0.5rem;
  border-collapse: separate;
  border-spacing: 0 var(--border-spacing);
  margin-top: calc(var(--border-spacing) * -1);
  margin-bottom: calc(var(--border-spacing) * -1);
}

::v-deep {
  th,
  td {
    position: relative;
    padding: 0 1rem;
    vertical-align: middle;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  th {
    height: 2.5rem;
    font-weight: 700;
    letter-spacing: $tracking-wide;
    color: $col-white;
    background-color: $col-primary-500;
  }

  td {
    height: 2rem;
    background-color: $col-white;
  }
}
</style>
