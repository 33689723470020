<template>
  <draggable
    :value="value"
    :tag="'tbody'"
    handle=".handle"
    :force-fallback="true"
    :animation="200"
    @input="updateValue($event)"
    @start="onStart($event)"
    @end="onOrderEnd($event)"
  >
    <slot></slot>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'TbodyDraggable',
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event)
    },
    onStart(event) {
      this.$emit('start', event)
      // compensate for added table height through added placeholder row at end of table
      const newMargin = `calc(var(--margin-bottom) - (2 * var(--border-spacing)))`
      event.target.parentElement.style.marginBottom = newMargin
      // hard code width of ghost tds to avoid horizontal shrinking whilst dragging
      const itemChildren = Array.from(event.item.children)
      const sizes = itemChildren.map(c => c.clientWidth)
      const draggedChildren = Array.from(document.querySelector('.sortable-drag').children)
      draggedChildren.forEach((child, i) => {
        child.style.width = sizes[i] + 'px'
      })
    },
    onOrderEnd(event) {
      this.$emit('end', event)
      // restore original margin-bottom^
      const restoredMargin = `calc(var(--margin-bottom) - var(--border-spacing))`
      event.target.parentElement.style.marginBottom = restoredMargin
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .sortable-drag {
    opacity: 1;

    td {
      background: $col-white;
    }
  }

  .sortable-ghost {
    opacity: 0;
  }
}
</style>
