<template>
  <form :style="`max-width: ${maxw}`" @submit.prevent="emitSubmit">
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'Form',
  props: {
    maxw: {
      type: String,
      default: '50rem',
    },
  },
  methods: {
    emitSubmit(event) {
      this.$emit('submit', event)
    },
  },
}
</script>

<style lang="scss" scoped>
form {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}
</style>
