<template>
  <svg viewBox="0 0 411 411">
    <title><slot>Logout</slot></title>
    <g>
      <path
        d="M206.7,163.6h28.9v-99a27.3,27.3,0,0,0-27.2-27.2H57.3A27.3,27.3,0,0,0,30.1,64.7V373.6H59V66.3H206.7Z"
      />
      <rect x="206.7" y="263.2" width="28.9" height="110.36" />
      <path
        d="M376.1,204.4l-80.2-53a10.8,10.8,0,0,0-16.8,9v29H118a10.9,10.9,0,0,0-10.8,10.8v26.2A10.9,10.9,0,0,0,118,237.4H279.1v29a10.8,10.8,0,0,0,16.8,9l80.2-53a10.8,10.8,0,0,0,0-18.1Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MenuLogoutIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
