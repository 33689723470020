<template>
  <svg viewBox="0 0 411 411">
    <title><slot>Help</slot></title>
    <path
      d="M256.3,65.7q19.6,9.7,29.5,25.9a65.3,65.3,0,0,1,9.9,34.6q0,17.5-6.1,29.9a64.6,64.6,0,0,1-14.6,20A232.8,232.8,0,0,1,252,193.6a229.2,229.2,0,0,0-20.5,15.5,54.8,54.8,0,0,0-12.4,16q-5,9.4-4.9,22.5v20.2H173.3V245.4q0-19.3,6.5-33.1t16-22a213.8,213.8,0,0,1,23.8-17.8q17.1-11.7,25-20.5t7.9-23.2q0-18.4-12.1-28.6T207,90.2q-36,0-62.5,32.4l-28.3-22Q153.9,56,209.7,56,236.7,56,256.3,65.7ZM217.3,323.2a30,30,0,0,1,9,22,30.7,30.7,0,0,1-9,22.3,30.2,30.2,0,0,1-22.5,9.2,29.6,29.6,0,0,1-22-9.2,30.7,30.7,0,0,1-9-22.3,30.7,30.7,0,0,1,31-31Q208.3,314.2,217.3,323.2Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'MenuHelpIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
