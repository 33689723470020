<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Link</slot>
    </title>
    <path
      d="M31.51,26.15h0a4.93,4.93,0,0,0,1.2,5.13,7,7,0,0,1,1.78,6.88,7.18,7.18,0,0,1-.89,1.93,7.07,7.07,0,0,1-.89,1.09l-5.66,5.66a7,7,0,0,1-9.9-9.9l5.66-5.66a7.07,7.07,0,0,1,1.09-.89A10.91,10.91,0,0,1,24,27.49a9.44,9.44,0,0,0-1.14,1L14.32,37A9,9,0,0,0,27.05,49.68l8.49-8.49a9.44,9.44,0,0,0,1-1.14A9,9,0,0,0,37.7,37.7a8.79,8.79,0,0,0,.45-3.21,7.57,7.57,0,0,0-.3-2,8.87,8.87,0,0,0-6.34-6.34ZM37,14.32l-8.49,8.49a9.44,9.44,0,0,0-1,1.14,9,9,0,0,0-1.34,7.56,8.87,8.87,0,0,0,6.34,6.34,5,5,0,0,0-1.2-5.14,7,7,0,0,1,0-9.9L37,17.15a7,7,0,1,1,9.9,9.9l-5.66,5.66a7.07,7.07,0,0,1-1.09.89,10.91,10.91,0,0,1-.05,2.91,9.44,9.44,0,0,0,1.14-1l8.49-8.49A9,9,0,0,0,37,14.32Z"
    />
  </svg>
</template>

<script>
export default {
  date: 'LinkIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
