<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Plus</slot>
    </title>
    <path
      d="M48,31v2a2,2,0,0,1-2,2H35V46a2,2,0,0,1-2,2H31a2,2,0,0,1-2-2V35H18a2,2,0,0,1-2-2V31a2,2,0,0,1,2-2H29V18a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2V29H46A2,2,0,0,1,48,31Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
}
</script>
