<template>
  <div>
    <GoBack :to="{ name: 'EigenschaftsartenIndex' }">Eigenschaftsarten</GoBack>
    <GoBack :to="{ name: 'EigenschaftsartenShow', params: { eigenschaftsartId } }">
      Eigenschaftsart: {{ eigenschaftsart.label }}
    </GoBack>

    <h1>Eigenschaftsart: &bdquo;{{ eigenschaftsart.label }}&ldquo; &rarr; bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="label">(internes) Label*</FormLabelInline>
          <FormInput
            v-model="formData.label"
            name="label"
            type="text"
            placeholder="Sackerl - Material"
            :af="true"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="titel">Titel (öffentlich)*</FormLabelInline>
          <FormInput
            v-model="formData.titel"
            name="titel"
            type="text"
            placeholder="Material"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="beschreibung">Beschreibung</FormLabelInline>
          <FormTextarea v-model="formData.beschreibung" name="beschreibung" width="32rem" />
        </FormRowFields>
      </FormBoxFields>
      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

export default {
  name: 'EigenschaftsartenEdit',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
  },
  props: ['eigenschaftsartId'],
  data() {
    return {
      formData: null,
      formErrors: null,
    }
  },
  computed: {
    eigenschaftsart() {
      return store.getters['eigenschaftsarten/eigenschaftsart'](this.eigenschaftsartId)
    },
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('eigenschaftsarten/getAll')
    next()
  },
  created() {
    this.formData = {
      label: this.eigenschaftsart.label,
      titel: this.eigenschaftsart.titel,
      beschreibung: this.eigenschaftsart.beschreibung,
    }
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('eigenschaftsarten/update', {
          id: this.eigenschaftsartId,
          formData: this.formData,
        })
        this.$router.push({
          name: 'EigenschaftsartenShow',
          params: { eigenschaftsartId: this.eigenschaftsartId },
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>
