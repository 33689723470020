<template>
  <div>
    <h1>Warenbewegungen</h1>

    <ListTable v-if="warenbewegungen">
      <thead>
        <tr>
          <th>Variante</th>
          <th>Art</th>
          <th>Veränderung</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="{ id, varianteId, warenbewegungsartId, veraenderung } in warenbewegungen"
          :key="id"
        >
          <TdLink :to="{ name: 'VariantenShow', params: { varianteId } }">
            {{ varianteId }}
          </TdLink>
          <td>{{ warenbewegungsarten.find(wbArt => wbArt.id == warenbewegungsartId).label }}</td>
          <td>{{ veraenderung }}</td>
        </tr>
      </tbody>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'

export default {
  name: 'WarenbewegungenIndex',
  components: {
    ...TableComponents,
  },
  computed: {
    warenbewegungen: () => store.getters['warenbewegungen/warenbewegungen'],
    warenbewegungsarten: () => store.getters['warenbewegungsarten/warenbewegungsarten'],
    varianten: () => store.getters['varianten/varianten'],
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('warenbewegungen/getAll'),
      store.dispatch('warenbewegungsarten/getAll'),
      store.dispatch('varianten/getAll'),
    ])
    next()
  },
}
</script>
