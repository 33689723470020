<template>
  <div class="flash" :class="[mode, { active: active }]">
    <slot>{{ message }}</slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FlashMessage',
  computed: {
    ...mapGetters('flashMessage', ['mode', 'message', 'active']),
  },
}
</script>

<style lang="scss" scoped>
.flash {
  display: none;
  position: fixed;
  top: 2rem;
  left: 50%;
  padding: 0.5rem 1rem;
  border-radius: $flash-border-radius;
  font-weight: $flash-font-weight;
  font-size: $flash-font-size;
  letter-spacing: $flash-tracking;
  text-transform: uppercase;
  color: $flash-std-col;
  background: $flash-std-bg;
  transform: translateX(-50%);
  z-index: 300;

  &.good {
    color: $flash-good-col;
    background: $flash-good-bg;
  }

  &.bad {
    color: $flash-bad-col;
    background: $flash-bad-bg;
  }

  &.active {
    display: block;
  }
}
</style>
