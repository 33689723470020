<template>
  <div>
    <GoBack :to="{ name: 'EigenschaftsartenIndex' }">Eigenschaftsarten</GoBack>

    <h1>Eigenschaftsart: &bdquo;{{ eigenschaftsart.label }}&ldquo;</h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Label (intern)</th>
          <td>
            <input type="text" v-model="formData.label" @keyup="formDataChanged($event)" />
          </td>
        </tr>
        <tr>
          <th>Titel (öffentlich)</th>
          <td>
            <input type="text" v-model="formData.titel" @keyup="formDataChanged($event)" />
          </td>
        </tr>
        <tr>
          <th>Beschreibung</th>
          <td>
            <textarea
              type="text"
              v-model="formData.beschreibung"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton :deactivated="!formDataAltered" @click="submit">
      Speichern
    </VButton>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'EigenschaftsartenEdit', params: { eigenschaftsartId } }">
      Bearbeiten
    </VButton>

    <VSpacer />

    <h2>Eigenschaften</h2>

    <VButton
      class="button"
      type="link"
      :to="{ name: 'EigenschaftenCreate', query: { eigArt: eigenschaftsartId } }"
    >
      NEU
    </VButton>

    <VSpacer mode="small" />

    <ListTable v-if="eigenschaften">
      <thead>
        <tr>
          <th>Titel</th>
          <th></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="({ id, titel }, i) in eigenschaften" :key="id">
          <TdLink :to="{ name: 'EigenschaftenShow', params: { eigenschaftId: id } }">
            {{ titel }}
          </TdLink>
          <td>
            <TableOrder :order="i + 1" @submit="setOrder({ id, position: $event })" />
          </td>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'EigenschaftsartenShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
  },
  props: ['eigenschaftsartId'],
  data() {
    return {
      formData: null,
      formErrors: null,
      formDataAltered: false,
    }
  },
  computed: {
    eigenschaftsart() {
      return store.getters['eigenschaftsarten/eigenschaftsart'](this.eigenschaftsartId)
    },
    eigenschaften() {
      return store.getters['eigenschaften/eigenschaftenForEigenschaftsart'](this.eigenschaftsartId)
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('eigenschaftsarten/getAll'),
      store.dispatch('eigenschaften/getAll'),
    ])
    next()
  },
  created() {
    this.formData = {
      label: this.eigenschaftsart.label,
      titel: this.eigenschaftsart.titel,
      beschreibung: this.eigenschaftsart.beschreibung,
    }
  },
  methods: {
    formDataChanged(/* event */) {
      /** // todo
       * validate event.target.value
       * if no errors, show saveChangesButton
       */
      this.formDataAltered = true
    },
    async submit() {
      try {
        await this.$store.dispatch('eigenschaftsarten/update', {
          id: this.eigenschaftsartId,
          formData: this.formData,
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
    setOrder(payload) {
      this.$store.dispatch('eigenschaften/setPosition', payload)
    },
    onOrderEnd(event) {
      const id = this.eigenschaften[event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch('eigenschaften/setPosition', { id, position })
    },
  },
}
</script>
