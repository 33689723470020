<template>
  <label :for="this.for" :style="`width: ${width}`">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'FormLabel',
  props: {
    for: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '16rem',
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  justify-self: end;
  height: 2.25rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
  text-align: right;
}
</style>
