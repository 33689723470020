import axios from 'axios'

const getDefaultState = () => ({
  produktbilder: [],
})

const state = getDefaultState()

const getters = {
  produktbilder: state => state.produktbilder,
  produktbild: state => id => state.produktbilder.find(bild => bild.id == id),
}

const mutations = {
  SET_ALL: (state, produktbilder) => (state.produktbilder = produktbilder),

  SET_ONE: (state, produktbild) => {
    const target = state.produktbilder.find(bild => bild.id == produktbild.id)
    Object.assign(target, produktbild)
  },

  ADD_ONE: (state, produktbild) => state.produktbilder.push(produktbild),

  SET_POSITION: (state, { id, position }) => {
    const index = state.produktbilder.findIndex(bild => bild.id == id)
    const prodId = state.produktbilder[index].produktId
    const removed = state.produktbilder.splice(index, 1)[0]

    let pos = 0
    const targetIndex = state.produktbilder.findIndex(bild => {
      return bild.produktId == prodId && position - 1 == pos++
    })
    state.produktbilder.splice(targetIndex, 0, removed)

    let order = 1
    state.produktbilder
      .filter(bild => bild.produktId == prodId)
      .forEach(bild => (bild.order = order++))
  },

  REMOVE_ONE: (state, id) => {
    const index = state.produktbilder.findIndex(bild => bild.id == id)
    state.produktbilder.splice(index, 1)
  },
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/produktbilder'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  create: async ({ commit, dispatch }, { formData, file }) => {
    try {
      const createUrl = '/produktbilder'
      const createRes = await axios.post(createUrl, formData)
      const produktbild = createRes.data.data
      const uploadUrl = `/produktbilder/${produktbild.id}/upload`
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      const uploadRes = await axios.post(uploadUrl, file, config)
      commit('ADD_ONE', uploadRes.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
      return uploadRes.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/produktbilder/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPosition: ({ commit, dispatch }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const url = `/produktbilder/${payload.id}/set-position`
      const data = { position: payload.position }
      axios.post(url, data)
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite refreshen',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  delete: async ({ commit, dispatch }, id) => {
    try {
      const url = `/produktbilder/${id}`
      await axios.delete(url)
      commit('REMOVE_ONE', id)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gelöscht',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'löschen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
