<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Undo</slot>
    </title>
    <path
      d="M54.09,36a13,13,0,0,1-13,13h-7.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5.5a11,11,0,0,0,0-22h-14a19,19,0,0,0,1,5.17c.29.77,0,1-.71.62-3.85-2.33-11.8-5.21-15-6.32-.74-.26-.74-.68,0-.94,3.2-1.11,11.15-4,15-6.32.7-.43,1-.15.71.62a19,19,0,0,0-1,5.17h16A13,13,0,0,1,54.09,36Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'UndoIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
