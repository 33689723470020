<template>
  <div>
    <h1>Eigenschaftsarten</h1>

    <VButton type="link" :to="{ name: 'EigenschaftsartenCreate' }">NEU</VButton>

    <VSpacer mode="small" />

    <ListTable v-if="eigenschaftsarten">
      <thead>
        <tr>
          <th>Label</th>
          <th></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="({ id, label }, i) in eigenschaftsarten" :key="id">
          <TdLink :to="{ name: 'EigenschaftsartenShow', params: { eigenschaftsartId: id } }">
            {{ label }}
          </TdLink>
          <td>
            <TableOrder :order="i + 1" @submit="setOrder({ id, position: $event })" />
          </td>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'

import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'EigenschaftsartenIndex',
  components: {
    ...TableComponents,
    VButton,
    VSpacer,
  },
  computed: {
    ...mapGetters('eigenschaftsarten', ['eigenschaftsarten']),
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('eigenschaftsarten/getAll')
    next()
  },
  methods: {
    setOrder(payload) {
      this.$store.dispatch('eigenschaftsarten/setPosition', payload)
    },
    onOrderEnd(event) {
      const id = this.eigenschaftsarten[event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch('eigenschaftsarten/setPosition', { id, position })
    },
  },
}
</script>
