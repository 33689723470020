<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Überschrift 2</slot>
    </title>
    <path
      d="M43.77,21.05C41,21.24,40,22,39.92,23.92c-.06,1.06-.14,2.85-.14,6.72v4.94c0,4.36-.55,7.86-3.09,10.54C34.63,48.27,31.61,49,28.56,49a12,12,0,0,1-7.34-2.08c-2.62-2-3.89-5-3.89-10.09V25.61c0-4-.36-4.25-3.85-4.56v-1.3H25.42v1.3c-3.48.24-3.84.58-3.84,4.56V36.74c0,6.15,2.92,9.62,8.1,9.62,6.07,0,8.11-3.87,8.11-11V30.64c0-3.86-.14-5.52-.23-6.73-.13-2-1.19-2.68-4.41-2.86v-1.3H43.77ZM27.05,14a2.4,2.4,0,0,1-2.38,2.4,2.44,2.44,0,0,1,0-4.88A2.4,2.4,0,0,1,27.05,14ZM36,14a2.42,2.42,0,1,1-2.42-2.48A2.42,2.42,0,0,1,36,14Zm19.3,36.84c-.37,1.26-.84,2.68-1.12,3.72H43.57v-.67c1.54-1.51,3.22-3.17,4.59-4.76,1.77-2.07,3.45-4.31,3.45-6.75s-1.24-3.67-3.28-3.67-3.14,1.51-3.86,2.61l-.65-.59,1.6-2.27a5.08,5.08,0,0,1,3.83-1.68A4.58,4.58,0,0,1,54,41.57c0,2.38-.86,4.06-3.52,6.78-1.21,1.26-3,3-4.18,4.12h5.35c1.51,0,1.93-.14,2.89-1.91Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Ueberschrift2Icon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
