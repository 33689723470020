import axios from 'axios'
import Vue from 'vue'

const getDefaultState = () => ({
  kategorien: [],
})

const state = getDefaultState()

const getters = {
  kategorien: state => state.kategorien,
  kategorie: state => id => state.kategorien.find(kat => kat.id == id),
}

const mutations = {
  SET_ALL: (state, kategorien) => (state.kategorien = kategorien),

  SET_ONE: (state, kategorie) => {
    const target = state.kategorien.find(kat => kat.id == kategorie.id)
    Object.assign(target, kategorie)
  },

  ADD_ONE: (state, kategorie) => state.kategorien.push(kategorie),

  SET_POSITION: (state, { id, position }) => {
    const index = state.kategorien.findIndex(kat => kat.id == id)
    const removed = state.kategorien.splice(index, 1)[0]
    state.kategorien.splice(position - 1, 0, removed)
    let order = 1
    state.kategorien.forEach(kat => (kat.order = order++))
  },

  SET_PUBLISHED: (state, id) => {
    const target = state.kategorien.find(kat => kat.id == id)
    Vue.set(target, 'publiziert', true)
  },

  SET_PRIVATE: (state, id) => {
    const target = state.kategorien.find(kat => kat.id == id)
    Vue.set(target, 'publiziert', false)
  },
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/kategorien'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  create: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/kategorien'
      const res = await axios.post(url, formData)
      commit('ADD_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/kategorien/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPosition: ({ commit, dispatch }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const url = `/kategorien/${payload.id}/set-position`
      const data = { position: payload.position }
      axios.post(url, data)
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite refreshen',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  publish: async ({ commit, dispatch }, id) => {
    commit('SET_PUBLISHED', id)
    try {
      const url = `/kategorien/${id}/publish`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'publiziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'publizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  unpublish: async ({ commit, dispatch }, id) => {
    commit('SET_PRIVATE', id)
    try {
      const url = `/kategorien/${id}/unpublish`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'depubliziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'depublizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
