<template>
  <router-link class="link" :to="to">
    <svg viewBox="0 0 64 64">
      <title>{{ hoverText }}</title>
      <path
        d="M38,32a6,6,0,0,1-.87,3.11,6.1,6.1,0,0,1-2,2A6,6,0,1,1,38,32Zm13.08-1.24C48.65,27.69,41.61,20,32,20S15.36,27.68,12.93,30.75a2,2,0,0,0,0,2.5C15.36,36.32,22.4,44,32,44a19.18,19.18,0,0,0,7.89-1.75l-2.71-2.9A8.9,8.9,0,0,1,32,41a9,9,0,1,1,9-9,8.9,8.9,0,0,1-1.65,5.18L43,40.59a33.35,33.35,0,0,0,8.08-7.35A2,2,0,0,0,51.08,30.76Zm-2.4,16.56-1.36,1.36a1,1,0,0,1-1.44,0l-6-6.41A22.46,22.46,0,0,0,43,40.59l5.66,5.29A1,1,0,0,1,48.68,47.32Z"
      />
    </svg>
  </router-link>
</template>

<script>
export default {
  name: 'TableButtonInspect',
  props: {
    to: {
      type: [String, Object],
      default: '/',
    },
    hoverText: {
      type: String,
      default: 'Inspect',
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  height: 1.5rem;
  cursor: pointer;

  svg {
    --increased-size-factor: 1.25;
    width: 1.5rem;
    fill: $col-gray-900;
    transition: transform ease 125ms, fill ease 125ms;
    transform: scale(var(--increased-size-factor));
  }

  &:focus,
  &:hover {
    svg {
      transform: scale(calc(1.25 * var(--increased-size-factor)));
      fill: $col-primary-500;
    }
  }
}
</style>
