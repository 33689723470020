<template>
  <svg viewBox="0 0 411 411">
    <title><slot>Page</slot></title>
    <path
      d="M309.3,47H102A14.3,14.3,0,0,0,87.8,61.2V347.5A14.3,14.3,0,0,0,102,361.8H309.3a14.3,14.3,0,0,0,14.3-14.3V61.2A14.3,14.3,0,0,0,309.3,47ZM216.5,268.4H135a6.7,6.7,0,0,1,0-13.5h81.5a6.7,6.7,0,1,1,0,13.5Zm59.8-36H135a6.7,6.7,0,1,1,0-13.5H276.3a6.7,6.7,0,1,1,0,13.5Zm0-36H135a6.7,6.7,0,1,1,0-13.5H276.3a6.7,6.7,0,1,1,0,13.5Zm0-36H135a6.7,6.7,0,1,1,0-13.5H276.3a6.7,6.7,0,1,1,0,13.5Zm0-36H135a6.7,6.7,0,1,1,0-13.5H276.3a6.7,6.7,0,1,1,0,13.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'MenuPageIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
