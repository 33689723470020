var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GoBack',{attrs:{"to":{ name: 'ProdukteIndex', query: { kat: _vm.produktgruppe.kategorieId } }}},[_vm._v(" Produkte: "+_vm._s(_vm.kategorie.titel.plural)+" ")]),_c('h1',[_vm._v("Produktgruppe: „"+_vm._s(_vm.produktgruppe.titel)+"“")]),_c('DetailsTable',[_c('tbody',[_c('tr',[_c('th',[_vm._v("Titel")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.titel),expression:"formData.titel"}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.titel)},on:{"keyup":function($event){return _vm.formDataChanged($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "titel", $event.target.value)}}})])]),_c('tr',[_c('th',[_vm._v("Kategorie")]),_c('td',[_vm._v(_vm._s(_vm.kategorie.titel.plural))])]),_c('tr',[_c('th',[_vm._v("Beschreibung")]),_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.beschreibung),expression:"formData.beschreibung"}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.beschreibung)},on:{"keyup":function($event){return _vm.formDataChanged($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "beschreibung", $event.target.value)}}})])]),_c('tr',[_c('th',[_vm._v("Produktfilter")]),_c('td',[(_vm.produktgruppe.produktfilter.length)?_vm._l((_vm.produktgruppe.produktfilter),function(ref){
var id = ref.id;
var titel = ref.titel;
return _c('p',{key:id},[_vm._v(_vm._s(titel))])}):_c('p',[_vm._v("—")])],2)])])]),_c('VSpacer',{attrs:{"mode":"small"}}),_c('VButton',{attrs:{"deactivated":!_vm.formDataAltered},on:{"click":_vm.submit}},[_vm._v("Speichern")]),_c('VSpacer',{attrs:{"mode":"small"}}),_c('VButton',{attrs:{"type":"link","to":{ name: 'ProduktgruppenEdit', params: { produktgruppeId: _vm.produktgruppeId } }}},[_vm._v(" Bearbeiten ")]),_c('VSpacer'),_c('h2',[_vm._v("Produkte")]),_c('VButton',{staticClass:"button",attrs:{"type":"link","to":{ name: 'ProdukteCreate', query: { gru: _vm.produktgruppeId } }}},[_vm._v(" NEU ")]),_c('VSpacer',{attrs:{"mode":"small"}}),(_vm.produkte)?_c('ListTable',[_c('thead',[_c('tr',[_c('th',[_vm._v("Produkt")]),_c('th',[_vm._v("Preis")]),_c('th'),_c('th',[_c('VisibleIcon',{staticClass:"table-icon"})],1)])]),_c('tbody',_vm._l((_vm.produkte),function(ref,i){
var id = ref.id;
var titel = ref.titel;
var eigenschaften = ref.eigenschaften;
var preis = ref.preis;
var neu = ref.neu;
var publiziert = ref.publiziert;
return _c('tr',{key:id},[_c('TdLink',{attrs:{"to":{ name: 'ProdukteShow', params: { produktId: id } }}},[(_vm.produktgruppe.produktfilter.length)?_vm._l((_vm.produktgruppe.produktfilter),function(filter){return _c('span',{key:filter.id,staticClass:"inline-list"},[_vm._v(" "+_vm._s(filter.titel)+": "+_vm._s(eigenschaften.find(function (e) { return e.eigenschaftsartId == filter.id; }).titel)+" ")])}):[_vm._v("<Default>")]],2),_c('td',[_vm._v(_vm._s(_vm.formatPrice(preis.cent)))]),_c('td',[(neu)?[_vm._v("neu")]:_vm._e()],2),_c('td',[_c('TableToggle',{attrs:{"name":"publizieren","value":publiziert},on:{"click":function($event){return _vm.toggleProduktPubliziert($event, id)}}})],1)],1)}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }