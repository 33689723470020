<template>
  <router-link class="link" :to="to">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'TableLink',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.link:hover {
  text-decoration: underline;
}
</style>
