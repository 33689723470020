<template>
  <div>
    <GoBack :to="{ name: 'ProdukteShow', params: { produktId: prod } }">
      Produkt:
      <template v-if="produktgruppe.produktfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in produkt.eigenschaften.filter(e =>
            produktgruppe.produktfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
    </GoBack>

    <h1>Produktbild &rarr; anlegen</h1>

    <VForm @submit="submit" ref="form">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="label">Label (=Hovertext)*</FormLabelInline>
          <FormInput
            v-model="formData.label"
            name="label"
            type="text"
            placeholder="Vorderseite"
            :rqd="true"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="file">File*</FormLabelInline>
          <input type="file" name="file" ref="file" required="required" />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="rahmen">Rahmen (rund um Bild)</FormLabelInline>
          <VToggle name="rahmen" :value="formData.rahmen" @click="toggleRahmen" />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton class="button" type="submit" :class="{ inactive: buttonLocked }">
          Speichern
        </VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'
import VToggle from '@/components/global/Toggle'

export default {
  name: 'ProduktbilderCreate',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
    VToggle,
  },
  props: ['prod'],
  data() {
    return {
      formData: {
        produktId: this.prod,
        label: '',
        rahmen: false,
      },
      formErrors: null,
      buttonLocked: false,
    }
  },
  computed: {
    produkt() {
      return store.getters['produkte/produkt'](this.prod)
    },
    produktgruppe() {
      return store.getters['produktgruppen/produktgruppe'](this.produkt.produktgruppeId)
    },
    eigenschaften: () => store.getters['eigenschaften/eigenschaften'],
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('produkte/getAll'),
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('eigenschaften/getAll'),
    ])
    next()
  },
  methods: {
    toggleRahmen(newValue) {
      this.formData.rahmen = newValue
    },
    async submit() {
      this.buttonLocked = true
      try {
        let file = new FormData()
        file.append('file', this.$refs.file.files[0])
        await this.$store.dispatch('produktbilder/create', {
          formData: this.formData,
          file,
        })
        this.$router.push({ name: 'ProdukteShow', params: { produktId: this.prod } })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>

<style scoped>
.button.inactive {
  pointer-events: none;
  opacity: 0.3;
}
</style>
