<template>
  <div>
    <h1>Bestellungen</h1>

    <!-- <VButton type="link" :to="{ name: 'BestellungenCreate' }">NEU</VButton> -->

    <!-- <VSpacer mode="small" /> -->

    <ListTable v-if="bestellungen">
      <thead>
        <tr>
          <th>Best.Nr.</th>
          <th>Name</th>
          <th>Summe</th>
          <th>Z.-Art</th>
          <th>Bestellt</th>
          <th>Bezahlt</th>
          <th>Versandt</th>
          <th>Abbruch</th>
          <th>Problem</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="{
            bestellnummer,
            zahlungsart,
            gesamtsumme,
            bestellung,
            bezahlung,
            versand,
            abbruch,
            problem,
            kunde,
          } in bestellungen"
          :key="bestellnummer"
        >
          <TdLink :to="{ name: 'BestellungenShow', params: { bestellnummer } }">
            {{ bestellnummer }}
          </TdLink>
          <td>{{ kunde.vorname }} {{ kunde.nachname }}</td>
          <td>{{ formatPrice(gesamtsumme) }}</td>
          <td>{{ zahlungsart }}</td>
          <td>{{ bestellung ? bestellung.datum : '' }}</td>
          <td>
            <template v-if="bezahlung">{{ bezahlung.datum }}</template>
            <TableButton v-else class="button" @click="setBezahlt(bestellnummer, $event)">
              BEZAHLT
            </TableButton>
          </td>
          <td>
            <template v-if="versand">{{ versand.datum }}</template>
            <TableButton v-else class="button" @click="setVersandt(bestellnummer, $event)">
              VERSANDT
            </TableButton>
          </td>
          <td>
            <template v-if="abbruch">{{ abbruch.datum }}</template>
            <TableButton v-else class="button" @click="setAbgebrochen(bestellnummer, $event)">
              ABBRUCH
            </TableButton>
          </td>
          <td>
            <template v-if="problem">{{ problem.datum }}</template>
            <TableButton v-else class="button" @click="setProblem(bestellnummer, $event)">
              PROBLEM
            </TableButton>
          </td>
        </tr>
      </tbody>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'

import formatPrice from '@/assets/js/formatPrice'

import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'BestellungenIndex',
  components: {
    ...TableComponents,
    VButton,
    VSpacer,
  },
  computed: {
    ...mapGetters('bestellungen', ['bestellungen']),
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('bestellungen/getAll')
    next()
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    setBezahlt(bestellnummer, event) {
      event.target.style.pointerEvents = 'none'
      event.target.style.opacity = '0.3'
      store.dispatch('bestellungen/setBezahlt', bestellnummer)
    },
    setVersandt(bestellnummer, event) {
      event.target.style.pointerEvents = 'none'
      event.target.style.opacity = '0.3'
      store.dispatch('bestellungen/setVersandt', bestellnummer)
    },
    setAbgebrochen(bestellnummer, event) {
      event.target.style.pointerEvents = 'none'
      event.target.style.opacity = '0.3'
      store.dispatch('bestellungen/setAbgebrochen', bestellnummer)
    },
    setProblem(bestellnummer, event) {
      event.target.style.pointerEvents = 'none'
      event.target.style.opacity = '0.3'
      store.dispatch('bestellungen/setProblem', bestellnummer)
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  padding: 0.125em 0.75em;
  font-size: 0.625rem;
  line-height: 1;
  letter-spacing: 0.125em;
  color: $col-gray-300;
  font-weight: $weight-semibold;
  border: 1px solid $col-gray-300;
  border-radius: 4px;

  &:hover {
    color: $col-black;
    border: 1px solid $col-black;
  }
}
</style>
