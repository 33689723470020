<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Expandable Text</slot>
    </title>
    <rect x="16" y="17" width="32" height="3" rx="0.5" />
    <rect x="16" y="26" width="32" height="3" rx="0.5" />
    <path
      d="M41.29,35H22.71c-.68,0-1,1.24-.43,1.83l8.85,9.75a1.11,1.11,0,0,0,1.74,0l8.85-9.75C42.26,36.24,42,35,41.29,35Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExpandableTextIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
