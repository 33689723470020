<template>
  <svg viewBox="0 0 411 411">
    <title><slot>Account</slot></title>
    <g>
      <path
        d="M287.9,217.2c-12.3-3.4-22.8-6.1-31.9-8.2-14.2,13.5-31.6,21.4-50.4,21.4s-36.2-8-50.4-21.4c-9.1,2.1-19.7,4.8-31.9,8.2C58.4,235.2,71.9,339.8,71.9,339.8c33.2,40.4,127.4,38.5,133.6,38.4s100.4,2.1,133.6-38.4C339.1,339.8,352.6,235.2,287.9,217.2Z"
      />
      <ellipse cx="205.5" cy="116.5" rx="63.5" ry="83.7" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MenuAccountIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
