import { Mark } from 'tiptap'
import { toggleMark } from 'tiptap-commands'

export default class MoreText extends Mark {
  get name() {
    return 'more_text'
  }

  get schema() {
    return {
      parseDOM: [
        {
          tag: 'span.more',
        },
      ],
      toDOM: () => [
        'span',
        {
          class: 'more',
        },
        0,
      ],
    }
  }

  commands({ type }) {
    return () => toggleMark(type)
  }
}
