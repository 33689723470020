<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Bulleted List</slot>
    </title>
    <rect x="25" y="17" width="24" height="3" rx="0.5" />
    <rect x="25" y="30" width="24" height="3" rx="0.5" />
    <rect x="25" y="43" width="24" height="3" rx="0.5" />
    <circle cx="17" cy="19" r="3" />
    <circle cx="17" cy="32" r="3" />
    <circle cx="17" cy="45" r="3" />
  </svg>
</template>

<script>
export default {
  name: 'BulletedListIcon',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
