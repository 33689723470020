<template>
  <div>
    <h1>Ausänge</h1>

    <ListTable v-if="aushaenge">
      <thead>
        <tr>
          <th>Label</th>
          <th>Bild</th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="{ id, label, conversions } in aushaenge" :key="id">
          <TdLink :to="{ name: 'AushaengeEdit', params: { aushangId: id } }">{{ label }}</TdLink>
          <td>
            <ResponsiveImage class="img" :conversions="conversions" sizes="240px" :alt="label" />
          </td>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'
import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'AushaengeIndex',
  components: {
    ...TableComponents,
    ResponsiveImage,
  },
  computed: {
    aushaenge: () => store.getters['aushaenge/aushaenge'],
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('aushaenge/getAll')
    next()
  },
}
</script>

<style lang="scss" scoped>
.img {
  height: 4rem;
}
</style>
