<template>
  <div>
    <GoBack :to="{ name: 'ProdukteIndex', query: { kat: produktgruppe.kategorieId } }">
      Produkte: {{ kategorie.titel.plural }}
    </GoBack>

    <h1>Produktgruppe: &bdquo;{{ produktgruppe.titel }}&ldquo;</h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Titel</th>
          <td>
            <input type="text" v-model="formData.titel" @keyup="formDataChanged($event)" />
          </td>
        </tr>
        <tr>
          <th>Kategorie</th>
          <td>{{ kategorie.titel.plural }}</td>
        </tr>
        <tr>
          <th>Beschreibung</th>
          <td>
            <textarea
              type="text"
              v-model="formData.beschreibung"
              @keyup="formDataChanged($event)"
            />
          </td>
        </tr>
        <tr>
          <th>Produktfilter</th>
          <td>
            <template v-if="produktgruppe.produktfilter.length">
              <p v-for="{ id, titel } in produktgruppe.produktfilter" :key="id">{{ titel }}</p>
            </template>
            <p v-else>&mdash;</p>
          </td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton :deactivated="!formDataAltered" @click="submit">Speichern</VButton>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'ProduktgruppenEdit', params: { produktgruppeId } }">
      Bearbeiten
    </VButton>

    <VSpacer />

    <h2>Produkte</h2>

    <VButton
      class="button"
      type="link"
      :to="{ name: 'ProdukteCreate', query: { gru: produktgruppeId } }"
    >
      NEU
    </VButton>

    <VSpacer mode="small" />

    <ListTable v-if="produkte">
      <thead>
        <tr>
          <th>Produkt</th>
          <th>Preis</th>
          <th></th>
          <th><VisibleIcon class="table-icon" /></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="({ id, titel, eigenschaften, preis, neu, publiziert }, i) in produkte" :key="id">
          <TdLink :to="{ name: 'ProdukteShow', params: { produktId: id } }">
            <template v-if="produktgruppe.produktfilter.length">
              <span
                class="inline-list"
                v-for="filter in produktgruppe.produktfilter"
                :key="filter.id"
              >
                {{ filter.titel }}:
                {{ eigenschaften.find(e => e.eigenschaftsartId == filter.id).titel }}
              </span>
            </template>
            <template v-else>&lt;Default&gt;</template>
          </TdLink>

          <td>{{ formatPrice(preis.cent) }}</td>

          <td>
            <template v-if="neu">neu</template>
          </td>

          <td>
            <TableToggle
              name="publizieren"
              :value="publiziert"
              @click="toggleProduktPubliziert($event, id)"
            ></TableToggle>
          </td>
        </tr>
      </tbody>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

import VisibleIcon from '@/components/cmsIcons/VisibleIcon'

export default {
  name: 'ProduktgruppenShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
    VisibleIcon,
  },
  props: ['produktgruppeId'],
  data() {
    return {
      formData: null,
      formErrors: null,
      formDataAltered: false,
    }
  },
  computed: {
    produktgruppe() {
      return store.getters['produktgruppen/produktgruppe'](this.produktgruppeId)
    },
    produkte() {
      return store.getters['produkte/produkteForProduktgruppe'](this.produktgruppeId)
    },
    kategorie() {
      return store.getters['kategorien/kategorie'](this.produktgruppe.kategorieId)
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('produkte/getAll'),
      store.dispatch('kategorien/getAll'),
    ])
    next()
  },
  created() {
    this.formData = {
      titel: this.produktgruppe.titel,
      beschreibung: this.produktgruppe.beschreibung,
    }
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    formDataChanged(/* event */) {
      /** // todo
       * validate event.target.value
       * if no errors, show saveChangesButton
       */
      this.formDataAltered = true
    },
    async submit() {
      try {
        await this.$store.dispatch('produktgruppen/update', {
          id: this.produktgruppeId,
          formData: this.formData,
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
    toggleProduktPubliziert(newValue, produktId) {
      if (newValue) {
        this.$store.dispatch('produkte/publish', produktId)
      } else {
        this.$store.dispatch('produkte/unpublish', produktId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.inline-list:not(:last-of-type)::after {
  display: inline-block;
  content: '|';
}

.table-icon {
  height: 2rem;
  fill: $col-white;
}
</style>
