<template>
  <div class="form-field-error" :style="`width: ${width}`">
    <div class="message"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'FormFieldError',
  props: {
    width: {
      type: String,
      default: '16rem',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';

.form-field-error {
  position: relative;
  margin: 0.5rem 0 0 auto;

  .message {
    color: $col-red-500;
    line-height: $leading-small;
  }
}
</style>
