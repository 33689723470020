import axios from 'axios'

const getDefaultState = () => ({
  eigenschaften: [],
})

const state = getDefaultState()

const getters = {
  eigenschaften: state => state.eigenschaften,
  eigenschaftenForEigenschaftsart: state => artId =>
    state.eigenschaften.filter(eig => eig.eigenschaftsartId == artId),
  eigenschaft: state => id => state.eigenschaften.find(eig => eig.id == id),
}

const mutations = {
  SET_ALL: (state, eigenschaften) => (state.eigenschaften = eigenschaften),

  SET_ONE: (state, eigenschaft) => {
    const target = state.eigenschaften.find(art => art.id == eigenschaft.id)
    Object.assign(target, eigenschaft)
  },

  ADD_ONE: (state, eigenschaft) => state.eigenschaften.push(eigenschaft),

  SET_POSITION: (state, { id, position }) => {
    const index = state.eigenschaften.findIndex(eig => eig.id == id)
    const artId = state.eigenschaften[index].eigenschaftsartId
    const removed = state.eigenschaften.splice(index, 1)[0]

    let pos = 0
    const targetIndex = state.eigenschaften.findIndex(eig => {
      return eig.eigenschaftsartId == artId && position - 1 == pos++
    })
    state.eigenschaften.splice(targetIndex, 0, removed)

    let order = 1
    state.eigenschaften
      .filter(eig => eig.eigenschaftsartId == artId)
      .forEach(eig => (eig.order = order++))
  },
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/eigenschaften'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
  },

  create: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/eigenschaften'
      const res = await axios.post(url, formData)
      commit('ADD_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/eigenschaften/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'gespeichert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'speichern fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPosition: ({ commit, dispatch }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const url = `/eigenschaften/${payload.id}/set-position`
      const data = { position: payload.position }
      axios.post(url, data)
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite refreshen',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
