<template>
  <div>
    <h1>Produkte</h1>

    <label for="kategorieFilter">Kategorie:&nbsp;</label>
    <select name="kategorieFilter" v-model="kategorieId">
      <option v-for="{ id, titel } in kategorien" :key="id" :value="id">
        {{ titel.plural }}
      </option>
    </select>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'ProduktgruppenCreate', query: { kat: kategorieId } }">
      NEUE PRODUKTGRUPPE
    </VButton>

    <VSpacer mode="small" />

    <ListTable>
      <thead>
        <tr>
          <th>Produktgruppe</th>
          <th>Produkt</th>
          <th><VisibleIcon class="table-icon" /></th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>

      <TbodyDraggable @end="onOrderEnd($event)">
        <tr
          v-for="({ id, produktgruppeId, eigenschaften, publiziert }, i) in filteredProdukte"
          :key="id"
        >
          <TdLink :to="{ name: 'ProduktgruppenShow', params: { produktgruppeId } }">
            {{ findProduktgruppe(produktgruppeId).titel }}
          </TdLink>

          <TdLink :to="{ name: 'ProdukteShow', params: { produktId: id } }">
            <template v-if="findProduktgruppe(produktgruppeId).produktfilter.length">
              <span
                class="inline-list"
                v-for="filter in findProduktgruppe(produktgruppeId).produktfilter"
                :key="filter.id"
              >
                {{ filter.titel }}:
                {{ eigenschaften.find(e => e.eigenschaftsartId == filter.id).titel }}
              </span>
            </template>
            <template v-else>&lt;Default&gt;</template>
          </TdLink>

          <td>
            <TableToggle
              name="publizieren"
              :value="publiziert"
              @click="togglePubliziert($event, id)"
            ></TableToggle>
          </td>

          <td>
            <TableOrder :order="i + 1" @submit="setOrder({ id, position: $event })" />
          </td>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'

import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

import VisibleIcon from '@/components/cmsIcons/VisibleIcon'
import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'ProdukteIndex',
  components: {
    ...TableComponents,
    VButton,
    VSpacer,
    VisibleIcon,
    OrderIcon,
  },
  props: ['kat'],
  data() {
    return {
      kategorieId: null,
    }
  },
  computed: {
    ...mapGetters('produktgruppen', ['produktgruppen']),
    ...mapGetters('produkte', ['produkte']),
    ...mapGetters('kategorien', ['kategorien']),
    kategorie() {
      return this.$store.getters['kategorien/kategorie'](this.kategorieId)
    },
    filteredProdukte() {
      return this.produkte.filter(prod => prod.kategorieId == this.kategorieId)
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('kategorien/getAll'),
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('produkte/getAll'),
    ])
    next()
  },
  created() {
    this.kategorieId = this.kat ?? this.kategorien[0].id
  },
  methods: {
    findProduktgruppe(id) {
      return this.$store.getters['produktgruppen/produktgruppe'](id)
    },
    setOrder(payload) {
      this.$store.dispatch('produkte/setPositionWithinKategorie', payload)
    },
    onOrderEnd(event) {
      const id = this.filteredProdukte[event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch('produkte/setPositionWithinKategorie', { id, position })
    },
    togglePubliziert(newValue, produktId) {
      if (newValue) {
        this.$store.dispatch('produkte/publish', produktId)
      } else {
        this.$store.dispatch('produkte/unpublish', produktId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.inline-list:not(:last-of-type)::after {
  display: inline-block;
  content: '|';
}

.table-icon {
  height: 2rem;
  fill: $col-white;
}
</style>
