import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import flashMessage from './flashMessage'

import bestellungen from './bestellungen'
import kategorien from './kategorien'
import preise from './preise'
import eigenschaftsarten from './eigenschaftsarten'
import eigenschaften from './eigenschaften'
import warenbewegungsarten from './warenbewegungsarten'
import warenbewegungen from './warenbewegungen'
import produktbilder from './produktbilder'
import produktgruppen from './produktgruppen'
import produkte from './produkte'
import varianten from './varianten'
import wallpapers from './wallpapers'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    flashMessage,
    bestellungen,
    kategorien,
    preise,
    eigenschaften,
    eigenschaftsarten,
    warenbewegungsarten,
    warenbewegungen,
    produktbilder,
    produktgruppen,
    produkte,
    varianten,
    wallpapers,
  },
})
