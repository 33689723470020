<template>
  <label class="switch">
    <input
      v-if="value"
      :name="name"
      class="checkbox checkbox--checked"
      type="checkbox"
      @click.prevent="handleClick"
      checked
    />
    <input v-else :name="name" class="checkbox" type="checkbox" @click.prevent="handleClick" />
    <span class="toggle"></span>
  </label>
</template>

<script>
// NOTE: HTML/CSS does not get updated in Firefox when checked-value changes
// This is the reason for the v-if/v-else-solution above
export default {
  name: 'TableToggle',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick() {
      // emit intended value
      this.$emit('click', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  width: max-content;
  align-items: center;
  cursor: pointer;
  font-family: $font;
  font-weight: $weight-semibold;
  letter-spacing: $tracking-wide;
}

.toggle {
  --toggle-width: 28px;
  --knob-width: 12px;
  --knob-height: 12px;
  display: inline-block;
  position: relative;
  width: var(--toggle-width);
  height: calc(var(--knob-height) + 4px);
  background: $col-gray-300;
  border-radius: calc(var(--knob-height) + 2px);
  transition: background-color 250ms ease;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: var(--knob-width);
    height: var(--knob-height);
    top: 50%;
    left: 50%;
    border: 1px solid $border-col;
    border-radius: calc(var(--knob-height));
    background: $col-white;
    transform: translate(calc(-50% - ((var(--toggle-width) - var(--knob-width)) / 2 - 2px)), -50%);
    transition: transform 250ms ease;
  }
}

.checkbox--checked + .toggle {
  background: $col-primary-500;

  &::after {
    transform: translate(calc(-50% + ((var(--toggle-width) - var(--knob-width)) / 2 - 2px)), -50%);
  }
}
</style>
