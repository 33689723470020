<template>
  <button>
    <order-icon class="orderToggle">
      <slot>Order</slot>
    </order-icon>
  </button>
</template>

<script>
import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'OrderToggle',
  components: {
    OrderIcon,
  },
  // todo functionality missing
}
</script>

<style lang="scss" scoped>
button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: $border-radius;
  background: transparent;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
}
.orderToggle {
  height: 1.75rem;
  fill: $col-white;
}
</style>
