import MenuIcons from './Icons'

export default [
  {
    title: 'Dashboard',
    route: 'Dashboard',
    icon: MenuIcons.MenuPagesIcon,
  },
  {
    title: 'Bestellungen',
    route: 'BestellungenIndex',
    icon: MenuIcons.MenuPagesIcon,
  },
  {
    title: 'Produkte',
    route: 'ProdukteIndex',
    icon: MenuIcons.MenuPagesIcon,
  },
  {
    title: 'Kategorien',
    route: 'KategorienIndex',
    icon: MenuIcons.MenuPagesIcon,
  },
  {
    title: 'Preise',
    route: 'PreiseIndex',
    icon: MenuIcons.MenuPagesIcon,
  },
  {
    title: 'Eigenschaften',
    route: 'EigenschaftsartenIndex',
    icon: MenuIcons.MenuPagesIcon,
  },
  {
    title: 'Wallpapers',
    route: 'WallpapersIndex',
    icon: MenuIcons.MenuPagesIcon,
  },
]
