<template>
  <svg viewBox="0 0 64 64">
    <title>
      <slot>Left</slot>
    </title>
    <path
      d="M50,31v2a2.07,2.07,0,0,1-2.12,2H23.25l7.46,7.46a2,2,0,0,1,0,2.83l-1.42,1.42a2,2,0,0,1-2.83,0L13.17,33.41a2,2,0,0,1,0-2.82l13.29-13.3a2,2,0,0,1,2.83,0l1.42,1.42a2,2,0,0,1,0,2.83L23.25,29H47.88A2.07,2.07,0,0,1,50,31Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeftIcon',
}
</script>
