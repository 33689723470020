<template>
  <div>
    <h1>Preise</h1>

    <VButton type="link" :to="{ name: 'PreiseCreate' }">NEU</VButton>

    <VSpacer mode="small" />

    <ListTable v-if="preise">
      <thead>
        <tr>
          <th>Label</th>
          <th>Preis</th>
          <th></th>
        </tr>
      </thead>

      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="({ id, label, cent }, i) in preise" :key="id">
          <TdLink :to="{ name: 'PreiseShow', params: { preisId: id } }">{{ label }}</TdLink>
          <td>{{ formatPrice(cent) }}</td>
          <td>
            <TableOrder :order="i + 1" @submit="setOrder({ id, position: $event })" />
          </td>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'PreiseIndex',
  components: {
    ...TableComponents,
    VButton,
    VSpacer,
  },
  computed: {
    preise: () => store.getters['preise/preise'],
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('preise/getAll')
    next()
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    setOrder(payload) {
      this.$store.dispatch('preise/setPosition', payload)
    },
    onOrderEnd(event) {
      const id = this.preise[event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch('preise/setPosition', { id, position })
    },
  },
}
</script>
