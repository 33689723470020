var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GoBack',{attrs:{"to":{ name: 'ProdukteIndex', query: { kat: _vm.kategorie.id } }}},[_vm._v(" Produkte: "+_vm._s(_vm.kategorie.titel.plural)+" ")]),_c('GoBack',{attrs:{"to":{ name: 'ProduktgruppenShow', params: { produktgruppeId: _vm.produktgruppe.id } }}},[_vm._v(" Produktgruppe: "+_vm._s(_vm.produktgruppe.titel)+" ")]),_c('h1',[_vm._v(" Produkt: "),(_vm.produktgruppe.produktfilter.length)?_vm._l((_vm.produkt.eigenschaften.filter(function (e) { return _vm.produktgruppe.produktfilter.map(function (f) { return f.id; }).includes(e.eigenschaftsartId); }
        )),function(ref){
        var id = ref.id;
        var titel = ref.titel;
return _c('span',{key:id,staticClass:"inline-list"},[_vm._v(" "+_vm._s(titel)+" ")])}):[_vm._v("<Default>")]],2),_c('DetailsTable',[_c('tbody',[_c('tr',[_c('th',[_vm._v("Teaser")]),_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.teaser),expression:"formData.teaser"}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.teaser)},on:{"keyup":function($event){return _vm.formDataChanged($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "teaser", $event.target.value)}}})])]),_c('tr',[_c('th',[_vm._v("Beschreibung")]),_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.beschreibung),expression:"formData.beschreibung"}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.beschreibung)},on:{"keyup":function($event){return _vm.formDataChanged($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "beschreibung", $event.target.value)}}})])]),_c('tr',[_c('th',[_vm._v("Hinweis")]),_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.hinweis),expression:"formData.hinweis"}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.hinweis)},on:{"keyup":function($event){return _vm.formDataChanged($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "hinweis", $event.target.value)}}})])]),(_vm.produkt.eigenschaften.length)?_vm._l((_vm.produkt.eigenschaften),function(ref,i){
        var id = ref.id;
        var eigenschaftsartId = ref.eigenschaftsartId;
        var titel = ref.titel;
return _c('tr',{key:id},[_c('th',[(i == 0)?[_vm._v("Eigenschaften")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(_vm.eigenschaftsarten.find(function (art) { return art.id == eigenschaftsartId; }).titel)+": "+_vm._s(titel)+" ")])])}):_c('tr',[_c('th',[_vm._v("Eigenschaften")]),_c('td',[_vm._v("—")])]),_c('tr',[_c('th',[_vm._v("Preis")]),_c('td',[_vm._v(_vm._s(_vm.formatPrice(_vm.produkt.preis.cent)))])]),_c('tr',[_c('th',[_vm._v("Quickbuy")]),_c('td',[_c('VToggle',{attrs:{"name":"quickbuy","value":_vm.formData.quickbuy},on:{"click":_vm.toggleQuickbuy}})],1)]),_c('tr',[_c('th',[_vm._v("Neu bis")]),_c('td',[(_vm.produkt.neu)?[_vm._v(" "+_vm._s(_vm.produkt.neuBis)+" (noch "+_vm._s(_vm.produkt.neuFuer)+" Tage) ")]:[_vm._v("—")]],2)]),(_vm.produkt.variantenfilter.length)?_vm._l((_vm.produkt.variantenfilter),function(ref,i){
        var id = ref.id;
        var titel = ref.titel;
return _c('tr',{key:id},[_c('th',[(i == 0)?[_vm._v("Variantenfilter")]:_vm._e()],2),_c('td',[_vm._v(_vm._s(titel))])])}):_c('tr',[_c('th',[_vm._v("Variantenfilter")]),_c('td',[_vm._v("—")])])],2)]),_c('VSpacer',{attrs:{"mode":"small"}}),_c('VButton',{attrs:{"deactivated":!_vm.formDataAltered},on:{"click":_vm.submit}},[_vm._v(" Speichern ")]),_c('VSpacer',{attrs:{"mode":"small"}}),_c('VButton',{attrs:{"type":"link","to":{ name: 'ProdukteEdit', params: { produktId: _vm.produktId } }}},[_vm._v(" Bearbeiten ")]),_c('VSpacer'),_c('VToggle',{attrs:{"name":"publizieren","value":_vm.produkt.publiziert},on:{"click":_vm.toggleProduktPubliziert}},[_vm._v(" publizieren ")]),_c('VSpacer'),_c('h2',[_vm._v("Varianten")]),_c('VButton',{staticClass:"button",attrs:{"type":"link","to":{ name: 'VariantenCreate', query: { prod: _vm.produktId } }}},[_vm._v(" NEU ")]),_c('VSpacer',{attrs:{"mode":"small"}}),(_vm.varianten)?_c('ListTable',[_c('thead',[_c('tr',[_c('th',[_vm._v("Variante")]),_c('th',[_vm._v("Preis")]),_c('th',[_vm._v("Bestand")]),_c('th',[_vm._v("...versteckt")]),_c('th',[_vm._v("...Warnung")]),_c('th',[_vm._v("Default")]),_c('th',[_c('VisibleIcon',{staticClass:"table-icon"})],1)])]),_c('tbody',_vm._l((_vm.varianten),function(ref){
        var id = ref.id;
        var titel = ref.titel;
        var eigenschaften = ref.eigenschaften;
        var preis = ref.preis;
        var publiziert = ref.publiziert;
        var bestand = ref.bestand;
return _c('tr',{key:id},[_c('TdLink',{attrs:{"to":{ name: 'VariantenShow', params: { varianteId: id } }}},[(_vm.produkt.variantenfilter.length)?_vm._l((_vm.produkt.variantenfilter),function(filter){return _c('span',{key:filter.id,staticClass:"inline-list"},[_vm._v(" "+_vm._s(filter.titel)+": "+_vm._s(eigenschaften.find(function (e) { return e.eigenschaftsartId == filter.id; }).titel)+" ")])}):[_vm._v("<Default>")]],2),_c('td',[(preis)?[_vm._v(_vm._s(_vm.formatPrice(preis.cent)))]:[_vm._v("—")]],2),_c('td',[_vm._v(" "+_vm._s(bestand.real)+" "),_c('TableButtonEdit',{attrs:{"type":"link","to":{ name: 'WarenbewegungenCreate', query: { var: id } }}})],1),_c('td',[_vm._v(_vm._s(bestand.versteckt))]),_c('td',[_vm._v(_vm._s(bestand.schwelle))]),_c('td',[(_vm.produkt.defaultVarianteId != id)?_c('span',{staticClass:"make-default",on:{"click":function($event){return _vm.makeDefault(id)}}},[_vm._v(" DEFAULT ")]):_c('span',{staticClass:"is-default"},[_vm._v("DEFAULT")])]),_c('td',[_c('TableToggle',{attrs:{"name":"publizieren","value":publiziert},on:{"click":function($event){return _vm.toggleVariantePubliziert($event, id)}}})],1)],1)}),0)]):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Produktbilder")]),_c('VButton',{staticClass:"button",attrs:{"type":"link","to":{ name: 'ProduktbilderCreate', query: { prod: _vm.produktId } }}},[_vm._v(" NEU ")]),_c('VSpacer',{attrs:{"mode":"small"}}),(_vm.produktbilderForProdukt)?_c('ListTable',[_c('thead',[_c('tr',[_c('th',[_vm._v("Bild")]),_c('th',[_vm._v("Label")]),_c('th',[_vm._v("Rahmen")]),_c('th',[_c('OrderIcon',{staticClass:"table-icon"})],1),_c('th')])]),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event)}}},_vm._l((_vm.produktbilderForProdukt),function(ref,i){
        var id = ref.id;
        var label = ref.label;
        var rahmen = ref.rahmen;
        var bild = ref.bild;
return _c('tr',{key:id},[_c('td',[_c('ResponsiveImage',{staticClass:"img",attrs:{"conversions":bild.conversions,"sizes":"240px","alt":label}})],1),_c('td',[_vm._v(_vm._s(label))]),_c('td',[_vm._v(_vm._s(rahmen ? 'ja' : 'nein'))]),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event })}}})],1),_c('td',[_c('TableButtonDelete',{attrs:{"hoverText":"Löschen"},on:{"click":function($event){return _vm.deleteProduktbild(id)}}})],1)])}),0)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }