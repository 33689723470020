<template>
  <div>
    <h1>Produktbilder</h1>

    <ListTable v-if="produktbilder">
      <thead>
        <tr>
          <th></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="({ id, label, bild }, i) in produktbilder" :key="id">
          <td>
            <ResponsiveImage
              class="img"
              :conversions="bild.conversions"
              sizes="240px"
              :alt="label"
            />
          </td>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'
import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'ProduktbilderIndex',
  components: {
    ...TableComponents,
    ResponsiveImage,
  },
  props: ['prod'],
  computed: {
    produktbilder: () => store.getters['produktbilder/produktbilder'],
    produktbilderForProdukt() {
      return this.produktbilder.filter(bild => bild.produktId == this.prod)
    },
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('produktbilder/getAll')
    next()
  },
}
</script>

<style lang="scss" scoped>
.img {
  height: 4rem;
}
</style>
