<template>
  <button
    v-if="type == 'button' || type == 'submit'"
    class="button"
    :type="type"
    :class="hoverColor"
    @click="handleClick"
  >
    <slot>Button</slot>
  </button>

  <router-link v-else class="link" :to="to" :class="hoverColor">
    <slot>Button</slot>
  </router-link>
</template>

<script>
export default {
  name: 'TableButton',
  props: {
    type: {
      type: String,
      validator(value) {
        return ['button', 'submit', 'link'].includes(value)
      },
      default: 'button',
    },
    hoverColor: {
      type: String,
      validator(value) {
        return ['green', 'red'].includes(value)
      },
      default: 'green',
    },
    to: {
      type: [String, Object],
      default: '/',
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.button,
.link {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  height: 1.5rem;
  transition: all ease 250ms;
  cursor: pointer;

  svg {
    width: 1.5rem;
    fill: $col-gray-900;
    transition: all ease 250ms;
  }

  &::v-deep:focus svg,
  &::v-deep:hover svg {
    fill: $col-primary-500;
    transform: scale(1.3);
  }

  &.red:focus svg,
  &.red:hover svg {
    fill: $col-red-500;
  }

  span {
    font-style: italic;
    margin-left: 0.5rem;
  }
}
</style>
