<template>
  <div class="page--login">
    <CmsLogo class="logo" />

    <VForm class="form" @submit.prevent="login()">
      <FormError v-if="error" width="16rem" bg="dark">
        Diese Zugangsdaten sind leider nicht richtig.
      </FormError>

      <FormRowFields>
        <FormInput
          ref="email"
          v-model="credentials.email"
          name="email"
          type="email"
          ph="E-Mail"
          :af="true"
          aria-label="E-Mail"
        ></FormInput>
      </FormRowFields>

      <FormRowFields>
        <FormInput
          v-model="credentials.password"
          name="password"
          type="password"
          ph="Passwort"
          aria-label="password"
        ></FormInput>
      </FormRowFields>

      <FormRowButtons class="form-row--button">
        <VButton type="submit" class="button" mode="std">Login</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CmsLogo from '@/components/global/CmsLogo.vue'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button.vue'

export default {
  components: {
    ...FormComponents,
    CmsLogo,
    VButton,
  },
  data() {
    return {
      credentials: {
        email: '',
        password: '',
      },
      error: false,
    }
  },
  methods: {
    ...mapActions({
      loginAction: 'auth/login',
    }),
    async login() {
      try {
        await this.loginAction(this.credentials)
        this.$router.push({ name: 'Dashboard' })
      } catch (e) {
        this.error = true
        this.credentials.password = ''
      }
    },
  },
  head() {
    return {
      title: 'Login',
    }
  },
}
</script>

<style lang="scss" scoped>
.page--login {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 6rem;
  margin: 0 auto;
  stroke: $cms-icon-col;
}

.form {
  margin-top: 4rem;
}
</style>
