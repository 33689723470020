<template>
  <div class="spacer" :class="mode">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Spacer',
  props: {
    mode: {
      type: String,
      validator: value => ['std', 'small'].includes(value),
      default: 'std',
    },
  },
}
</script>

<style lang="scss" scoped>
.spacer {
  &.std {
    margin-bottom: 4rem;
  }

  &.small {
    margin-bottom: 2rem;
  }
}
</style>
